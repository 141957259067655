.new-faq {
  position: relative;
  overflow: hidden;
  h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #ffffff;

    @include respond(950px) {
      font-size: 24px;
    }
  }
  .sub-heading {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 50px;
    text-align: left;
  }
  &__home-intro {
    padding: 120px 15% 150px 15%;
    background-color: #1f3d87;
    @include respond($desktop_minimum) {
      padding: 80px 6.675% 100px 6.675%;
    }

    .search-wrap {
      width: 70%;

      @include respond(750px) {
        width: 100%;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 70px;
    }
  }
  &__wrapper {
    position: relative;
    background-color: white;
    width: 100%;
  }

  &__header {
    padding: 100px 20% 50px 20%;
    margin: 0 auto;
    background-color: #1f3d87;
    text-align: left;
    color: #ffffff;

    .sub-heading {
      color: #ffffff;
      font-size: 15px;
      font-size: 500;
      margin-bottom: 50px;
    }

    @include respond($desktop_minimum) {
      padding: 100px 6.675% 50px 6.675%;
    }
  }

  &__search-wrapper {
    position: relative;

    .filtered-article {
      position: absolute;
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-top: 20px;
      height: fit-content;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      bottom: 0;
      top: 50px;
      left: 0;
      width: 100%;

      div {
        margin-bottom: 10px;
        border: 1px solid #e2e4e8;
        width: 100%;
        padding: 18px;
        text-align: left;
        border-radius: 8px;
        color: #000000 !important;
        cursor: pointer;

        h4 {
          font-size: 20px;
          font-weight: 600;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }

  &__search-input-container {
    margin-top: 10px;
    display: flex;
    position: relative;
    align-items: center;
    height: 60px;
    border: 1px solid #50669b;
    background-color: #50669b;
    border-radius: 6px;
    padding: 10px 15px;
    width: 100%;
    transition: background-color 0.3s;

    .gray-svg {
      display: none;
    }

    .white-svg {
      display: block;
    }

    &:focus-within {
      background-color: white;
      color: black;
      border: 1px solid white;

      .white-svg {
        display: none;
      }

      .gray-svg {
        display: block;
      }
    }

    .search-icon {
      width: 24px;
    }

    input {
      border: none;
      outline: none;
      flex-grow: 1;
      padding: 10px 10px;
      font-size: 16px;
      background-color: transparent;
      border-radius: 4px;
      height: 100%;
      color: white;

      &::placeholder {
        color: #ffffff;
        font-size: 18px;
      }

      &:focus {
        color: #000000;

        &::placeholder {
          color: #cccc;
        }
      }
    }
  }

  &__article-group {
    padding: 0 15% 0 15%;
    background-color: #ffffff;
    @include respond($desktop_minimum) {
      padding: 0 6.675% 50px 6.675%;
    }
    .article-card-group {
      position: relative;
      top: -50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
    }

    .article-card {
      width: 48%;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      background-color: #ffffff;
      border-radius: 6px;
      padding: 2% 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;
      text-decoration: none;

      @include respond(750px) {
        width: 100%;
        padding: 5%;
      }
    }
    .article-card:last-child {
      margin-left: auto;
      margin-right: auto;
    }
    h4 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      margin-top: 40px;
      margin-bottom: 20px;
      color: #000000;

      @include respond(950px) {
        font-size: 24px;
      }
    }
    p {
      color: #000000;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 20px;

      @include respond(950px) {
        font-size: 18px;
      }
    }

    .footer-span {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 15px;
        font-weight: 500;
        padding-left: 20px;
        color: #acacac;
      }
    }
  }

  &__article-body {
    padding: 50px 20% 50px 20%;
    background-color: #ffffff;
    @include respond($desktop_minimum) {
      padding: 50px 5% 100px 5%;
    }

    .page-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      a {
        text-decoration: none;
        color: #000000;
        cursor: pointer;
        font-size: 15px;
      }
      a:hover {
        color: #999;
      }

      .current-link {
        color: #999;
        font-size: 15px;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: fit-content;
        color: #000000;
        margin: 0 5px 5px 5px;
      }

      @include respond(500px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .article-heading {
      text-align: left;
      margin-top: 50px;
      h2 {
        font-size: 28px;
        font-weight: 600;
        color: #000000;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }

      .footer-span {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          font-size: 14px;
          padding-left: 10px;
          color: #acacac;
        }
      }
    }

    .article-container {
      .flexed-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
      }

      .show {
        display: block;
      }

      .hide {
        display: none;
      }

      .accordion-wrapper {
        margin-top: 40px;
        border: 1px solid #e2e4e8;
        border-radius: 8px;
        background-color: #ffffff;
        width: 100%;
        padding: 10px;
        text-align: left;

        .content {
          margin-bottom: 20px;
          padding: 16px;
          border-radius: 6px;

          @include respond(500px) {
            padding: 8px;
          }

          /* Normal styling */
          table {
            border: #1f3d87 solid 1px;
            width: 100%;
            margin-top: 30px;
          }

          th,
          td {
            font-size: 16px !important;
            line-height: 24px;
            border-right: #1f3d87 solid 1px;
            padding: 8px;
          }

          tr {
            border-bottom: #1f3d87 solid 1px;
          }

          strong {
            font-size: 18px !important;
          }

          @include respond(600px) {
            table {
              display: block;
              overflow-x: auto;
            }

            th {
              font-size: 16px !important;
              line-height: 20px !important;
              padding: 10px;
            }
            th:last-child {
              margin-bottom: 30px;
            }
            td,
            td li {
              font-size: 14px !important;
              line-height: 20px !important;
              padding: 5px;
            }

            td li {
              margin-bottom: 0;
            }

            tr {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              border-bottom: none;
              margin-bottom: 5px;
            }

            th,
            td:first-child {
              width: 100%;
              text-align: left;
            }

            th,
            td {
              border-right: none;
            }

            td:last-child {
              margin-top: 5px;
            }
          }

          .volumetric-img-web {
            object-fit: contain;
            width: 76%;
            margin-top: 30px;
            display: block;
          }
          .volumetric-img-mobile {
            display: none;
          }
          @include respond(600px) {
            .volumetric-img-web {
              display: none;
            }
            .volumetric-img-mobile {
              display: block;
              width: 84%;
              height: auto;
              margin: 30px auto;
            }
          }
        }

        .content:hover {
          background: #f2f7fd;
        }

        .active {
          background: #f2f7fd;
          color: #1f3d87;
        }

        .active h2 {
          color: #1f3d87;
        }

        ul {
          margin-top: 20px;
          list-style: numeric;
          li {
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 28px;
          }
        }
        i {
          display: block;
        }
        .account-type-img {
          object-fit: contain;
          height: 500px;
          width: 90%;
          margin-top: 30px;

          @include respond(500px) {
            height: 300px;
            margin-top: 0;
          }
        }

        .set-pricing-img {
          object-fit: contain;
          height: 300px;
          width: 70%;
          margin: 10px auto;
          display: block;

          @include respond(500px) {
            height: 200px;
            width: 100%;
          }
        }
        .business-name-img {
          object-fit: contain;
          height: 200px;
          width: 90%;
          margin-top: 30px;

          @include respond(500px) {
            height: 150px;
            margin-top: 10px;
          }
        }
        h2 {
          font-size: 18px;
          color: #000000;
          font-weight: 600;
          margin-bottom: 0;

          @include respond(500px) {
            font-size: 16px;
            line-height: 28px;
          }
        }
        .content:hover h2 {
          color: #1f3d87;
        }

        a {
          color: #1f3d87;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          margin-top: 20px;
        }
      }
    }
  }
}
