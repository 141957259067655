.faq {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(#000, 0.8);
  opacity: 0;
  visibility: hidden;
  display: block;

  &--show {
    opacity: 1;
    visibility: visible;
    z-index: 200000;
    animation: slideInUp 0.5s;
  }

  &--default {
    animation: slideOut 0.5s;
  }

  &__header {
    background-color: #002461;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;

    & figure {
      cursor: pointer;
    }
  }

  &__body {
    background-color: #0f254a;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    overflow-y: auto;
    padding-bottom: 4rem;

    &--show {
      // animation: blowIn2 1.3s ease-in-out;
    }

    & h1 {
      color: white;
      font-weight: 700;
    }

    &__list {
      width: 60%;
      margin-top: 10rem;
      list-style: none;

      @include respond(tablet) {
        width: 90%;
      }
    }
  }

  &__item {
    border-top: 1px solid #9e9e9e;
    overflow: hidden;

    &:last-child {
      border-bottom: 1px solid #9e9e9e;
    }

    &__question {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 0;
      color: white;
      font-size: 1.7rem;
      cursor: pointer;
    }
    &__answer {
      color: white;
      padding-left: 1.5rem 0;

      font-size: 1.2rem;
      transition: all 0.2s ease-in;

      & p {
        padding-bottom: 1rem;
        max-width: 70%;
        line-height: 2rem;
        padding-bottom: 2rem;

        @include respond(tablet) {
          max-width: 90%;
        }
      }
    }
  }
}
/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebe8e8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(116, 116, 116);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
