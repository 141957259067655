.blog-detail {
  width: 820px;
  padding: 10rem 0;
  margin: 0 auto;
  text-align: left;

  &__loader {
    height: 95vh;
    display: flex;
    align-items: flex-start;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: bold;
    line-height: 1.3;
    padding: 2rem 0;
  }

  &__image {
    width: 820px;

    img {
      width: 100%;
    }
  }

  &__metadata {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 10px;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 30px;
    }

    span {
      font-size: 1rem;
      margin-right: 5px;
    }

    img {
      width: 15px;
      margin-right: 5px;
    }
  }

  &__content {
    img {
      width: 700px;
      margin: 0 auto;
      text-align: center;
    }

    p {
      font-size: 1.5rem;
      line-height: 2;
      margin-bottom: 2rem;
    }

    ol,
    ul {
      font-size: 1.5rem;
      line-height: 2;
    }
  }

  @include respond(mobile) {
    width: 350px;

    h1 {
      font-size: 3rem;
    }

    &__metadata {
      span {
        font-size: 1.3rem;
        margin-right: 5px;
      }
    }

    &__image {
      width: 350px;

      img {
        width: 100%;
      }
    }

    &__content {
      img {
        width: 350px;
        height: 200px;
        margin: 0 auto;
        text-align: center;
      }

      h1 {
        font-size: 2.8rem;
        padding: 3rem 0 0.8rem 0;
      }

      p {
        font-size: 1.5rem;
        line-height: 2;
        margin-bottom: 2rem;
      }

      ol,
      ul {
        font-size: 1.5rem;
        line-height: 2;
      }
    }
  }
}
