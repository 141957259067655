.about-us {
  &__intro {
    background-color: var(--color-blue-darker);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vw 4rem;
    padding-top: 13vw;
    padding-bottom: 16vw;
    text-align: center;

    & h1 {
      font-size: calc(2.2vw + 0.7rem);
      font-weight: 700;
      max-width: 70rem;

      @include respond(tablet) {
        font-size: 3rem;
        max-width: 100%;
        line-height: 4rem;
      }
    }

    & p {
      font-size: calc(0.8vw + 0.8rem);
      margin-top: 3rem;
      max-width: 75rem;
      line-height: 2.5rem;
    }

    @include respond(tablet) {
      padding: 22vw 4rem;
      & h1 {
        font-size: 2.8rem;
        max-width: 100%;
        line-height: 4rem;
      }

      & p {
        font-size: 1.7rem;
        max-width: 100%;
        font-weight: 400;
        line-height: 2.8rem;
      }
    }
  }

  &__summary {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    padding: 15vw 4rem;
    align-items: center;

    &__image {
      & > div {
        width: 100%;
      }
    }
    &__text {
      padding: 1rem 4rem;

      & h2 {
        color: var(--color-blue-dark);
        font-weight: 700;
        font-size: 2.2rem;
      }

      & p {
        font-size: 1.4rem;
        line-height: 3rem;
        margin-top: 2rem;
        max-width: 70vw;
      }
    }

    @include respond(tablet) {
      display: flex;
      flex-direction: column;
      padding: 14vw 2rem;

      &__image {
        & > div {
          padding: 0;
          width: 100%;
        }
      }

      &__text {
        padding: 1rem 0;
        margin-top: 2rem;
        & h2 {
          font-size: 2.2rem;
        }

        & p {
          font-size: 1.5rem;
          line-height: 3rem;
          max-width: 100%;
        }
      }
    }
  }
  &__team {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    grid-auto-flow: row;
    grid-row-gap: 4rem;
    grid-column-gap: 2rem;
    padding: 7rem 4rem;

    &__person {
      display: flex;
      flex-direction: column;
      align-items: center;

      & h3 {
        font-size: 1.7rem;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        color: black;

        & p {
          font-size: inherit;
          color: black;
          margin: 0;
          margin-right: 0.5rem;
          &::first-letter {
            color: var(--color-yellow);
          }
        }
      }

      & p {
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 0.56);
      }
    }

    @include respond(tablet) {
      grid-template-columns: repeat(2, 1fr);
      padding: 7rem 2rem;
      grid-column-gap: 1rem;
      &__person {
        & h3 {
          font-size: 1.5rem;
          margin-top: 1rem;
        }

        & p {
          font-size: 1.5rem;
          color: rgba(0, 0, 0, 0.56);
        }
      }
    }
  }
  &__values {
    padding: 4rem 4rem 40rem 4rem;
    background: rgba(196, 196, 196, 0.12);

    & h1 {
      font-size: 2.5rem;
      font-weight: 700;
      color: var(--color-blue-dark);
    }
  }
  &__map {
    background-color: var(--color-blue-darker);
    padding: 4rem;

    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;

    @include respond(tablet) {
      padding: 4rem 2rem;
    }

    & h2 {
      font-weight: 700;
      font-size: 2.5rem;
    }

    p {
      font-size: 1.4rem;
      font-weight: 400;
      max-width: 40rem;
      line-height: 2.5rem;
      margin-top: 2rem;
    }

    & figure {
      align-self: center;
      margin-top: 5rem;
      padding-left: 15vw;

      @include respond(tablet) {
        transform: scale(1.1);
        padding-left: 10vw;
      }
    }
  }

  &__address {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5rem;
    align-items: center;
    padding: 7rem 4rem;
    &__map {
      height: fit-content;
    }

    &__text {
      & h1 {
        font-size: 3rem;
        color: var(--color-blue-dark);
        font-weight: 600;
      }

      & p {
        font-size: 1.4rem;
        max-width: 70%;
        line-height: 2rem;
      }
    }

    @include respond(tablet) {
      display: flex;
      flex-direction: column;
      padding: 7rem 2rem;
      padding-bottom: 0;

      &__text {
        padding: 7rem 2rem;
        background-color: #f8f8f8;
        width: 100vw;
        margin-top: 3rem;
        margin-top: 3rem;
        & h1 {
          font-size: 2.4rem;
        }
        margin-top: 3rem;
        & p {
          font-size: 1.4rem;
          max-width: 70%;
          line-height: 2rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.about-us__arrow-link {
  display: flex;
  align-items: center;

  & a {
    color: var(--color-blue-dark);
    font-size: 1.4rem;
    margin-left: 1rem;
  }
}
