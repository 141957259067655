.shipping-checklist {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #000000;
    width: 85%;
    margin: 24px auto;
    position: relative;

    @include respond(1390px) {
      width: 90%;
    }
    @include respond($desktop_average) {
      font-size: 40px;
      line-height: 54px;
    }
    @include respond($tablet_maximum) {
      font-size: 34px;
      line-height: 48px;
      width: 100%;
    }
    @include respond(600px) {
      font-size: 28px;
      line-height: 40px;
    }
    @include respond(400px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 38px;
    font-weight: 600;

    @include respond($tablet_maximum) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  &__intro-section {
    padding: 120px 14.675% 100px 14.675%;
    background-color: #fff;
    @include respond($desktop_minimum) {
      padding: 100px 6.675% 50px 6.675%;
    }

    .intro-wrap {
      width: 60%;
      margin: 0 auto;

      @include respond($desktop_minimum) {
        width: 80%;
      }
      @include respond(600px) {
        width: 100%;
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: #0f1414;
      margin: 0;
      text-align: center;

      @include respond(600px) {
        font-size: 14px;
      }
    }
  }

  &__guidelines {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 100px 14.675% 100px 14.675%;
    background: linear-gradient(
      180deg,
      rgba(228, 238, 252, 0) 3.12%,
      #e4eefc 14.58%,
      rgba(228, 238, 252, 0.9) 74.48%,
      rgba(228, 238, 252, 0.39) 97.92%
    );
    @include respond($desktop_minimum) {
      padding: 50px 6.675% 100px 6.675%;
    }
    .tag {
      font-size: 16px;
      color: #0f1414;
      font-weight: 500;

      @include respond(600px) {
        font-size: 14px;
      }
    }
    h2 {
      color: #22428f !important;
    }

    .guideline-group {
      background-color: #fff;
      padding: 20px;
      border-radius: 16px;
      width: 55%;
      margin: 30px auto 0;

      @include respond(1200px) {
        width: 80%;
      }

      @include respond(600px) {
        width: 100%;
        padding: 15px 10px;
      }
    }

    .list {
      position: relative;
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 28px;
      color: #000000;
      font-weight: 500;
      text-align: left !important;

      @include respond(600px) {
        font-size: 14px;
        line-height: 24px;
      }

      .true-shipper {
        position: absolute;
        bottom: 100%;
        background-color: #fff;
        border: 1px solid #e2e4e8;
        box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 30px;
        width: fit-content;
        z-index: 30;

        @include respond(600px) {
          padding: 15px;
          .hide-break {
            display: none;
          }
        }
        @include respond(791px) {
          .hide-break {
            display: none;
          }
        }
      }

      .correct-true-shipper {
        display: flex;
        align-items: center;
        p {
          color: #22428f;
          font-size: 14px;
          padding-left: 5px;
        }

        @include respond(600px) {
          margin: 12px 0;
          p {
            font-size: 12px;
          }
        }
      }

      .wrong-true-shipper {
        display: flex;
        align-items: center;

        p {
          color: #9e7201;
          font-size: 14px;
          padding-left: 5px;

          @include respond(600px) {
            font-size: 12px;
          }
        }
      }
    }

    img {
      padding-right: 6px;
      padding-top: 2px;
    }
  }

  &__prohibited-item {
    background-color: #fff;
    padding: 120px 0 100px;
    height: fit-content;
    position: relative;

    @include respond(789px) {
      padding: 100px 6.675% 50px 6.675%;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: #0f1414;
      margin: 0;
      text-align: center;

      @include respond(600px) {
        font-size: 15px;
      }
    }
    .prohibited-group {
      background: linear-gradient(
        180deg,
        rgba(228, 238, 252, 0) 3.12%,
        #e4eefc 14.58%,
        rgba(228, 238, 252, 0.9) 74.48%,
        rgba(228, 238, 252, 0.39) 97.92%
      );
      width: 100%;
      padding: 120px 0;
      height: 500px;
      overflow: visible;
      position: absolute;
      z-index: 0;
    }
    .prohibited-group-2 {
      background: linear-gradient(
        180deg,
        rgba(228, 238, 252, 0) 3.12%,
        #e4eefc 14.58%,
        rgba(228, 238, 252, 0.9) 74.48%,
        rgba(228, 238, 252, 0.39) 97.92%
      );
      width: 100%;
      top: 10%;
      padding: 100px 0;
      height: 700px;
      overflow: visible;
      position: absolute;
      z-index: 0;
    }

    @include respond(789px) {
      .prohibited-group,
      .prohibited-group-2 {
        display: none;
      }
    }
  }

  &__prohibited-export {
    background-color: #fff;
    padding: 100px 0;
    height: fit-content;
    position: relative;

    @include respond(789px) {
      padding: 50px 6.675% 50px 6.675%;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: #0f1414;
      margin: 0;
      text-align: center;

      @include respond(600px) {
        font-size: 15px;
      }
    }

    .footer-info {
      border: 1px solid #e2e4e8;
      border-radius: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 15px 20px;
      width: 70%;
      margin: 20px auto 0;

      @include respond($desktop_minimum) {
        width: 90%;
      }

      @include respond(600px) {
        width: 100%;
        padding: 15px 10px;
      }

      h3 {
        color: #22428f;
        text-align: left;
        padding-left: 6px;
      }

      img {
        padding-top: 5px;
      }
    }
  }

  &__documentation {
    background-color: #fff;
    padding: 100px 0;
    height: fit-content;
    position: relative;

    @include respond(789px) {
      padding: 50px 6.675% 50px 6.675%;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: #0f1414;
      margin: 0;
      text-align: left;

      @include respond(600px) {
        font-size: 15px;
      }
    }

    h3 {
      text-align: left;
    }

    .footer-info {
      border: 1px solid #e2e4e8;
      border-radius: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 15px 20px;
      width: 50%;
      margin: 20px auto 0;

      @include respond($desktop_minimum) {
        width: 70%;
      }

      @include respond(600px) {
        width: 100%;
        padding: 15px 10px;
      }

      h3 {
        color: #22428f;
        text-align: left;
        padding-left: 6px;
      }

      img {
        padding-top: 5px;
      }
    }
    .prohibited-group {
      background: linear-gradient(
        180deg,
        rgba(228, 238, 252, 0) 3.12%,
        #e4eefc 14.58%,
        rgba(228, 238, 252, 0.9) 74.48%,
        rgba(228, 238, 252, 0.39) 97.92%
      );
      width: 100%;
      padding: 120px 0;
      height: 38%;
      overflow: visible;
      position: absolute;
      z-index: 0;
    }

    @include respond(789px) {
      .prohibited-group {
        display: none;
      }
    }
  }

  &__non-serving {
    background-color: #fff;
    padding: 120px 0 100px;
    height: fit-content;
    position: relative;

    @include respond(789px) {
      padding: 100px 3.675% 50px 3.675% !important;
    }

    li {
      line-height: 0 !important;
    }

    .sub-heading {
      width: 50%;
      text-align: center;
      margin: 0 auto;

      @include respond(1000px) {
        width: 80%;
      }

      @include respond(500px) {
        width: 100%;
      }
    }

    .row-2 {
      padding-left: 20%;

      @include respond(1160px) {
        padding-left: 0 !important;
      }
    }

    .country {
      font-size: 20px;
      margin-left: -20px;
      white-space: nowrap;

      @include respond($desktop_minimum) {
        font-size: 16px;
      }

      @include respond(1160px) {
        text-align: left;
      }
      @include respond(600px) {
        margin-left: 0;
      }
    }

    .list-group {
      width: 55% !important;
      padding: 80px 50px 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      @include respond($desktop_minimum) {
        width: 80% !important;
      }
      @include respond(1160px) {
        flex-direction: column;

        ul {
          padding-left: 0 !important;
        }
      }
      @include respond(600px) {
        width: 100% !important;
        padding: 15px 10px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      color: #0f1414;
      margin: 0;
      text-align: center;

      @include respond(600px) {
        font-size: 15px;
      }
    }

    .prohibited-group {
      background: linear-gradient(
        180deg,
        rgba(228, 238, 252, 0) 3.12%,
        #e4eefc 14.58%,
        rgba(228, 238, 252, 0.9) 74.48%,
        rgba(228, 238, 252, 0.39) 97.92%
      );
      width: 100%;
      height: 60%;
      overflow: visible;
      position: absolute;
      z-index: 0;
      bottom: 0;
    }

    .prohibited-group-2 {
      background: linear-gradient(
        180deg,
        rgba(228, 238, 252, 0) 3.12%,
        #e4eefc 14.58%,
        rgba(228, 238, 252, 0.9) 74.48%,
        rgba(228, 238, 252, 0.39) 97.92%
      );
      width: 100%;
      top: 10%;
      padding: 100px 0;
      height: 700px;
      overflow: visible;
      position: absolute;
      z-index: 0;
    }

    @include respond(789px) {
      .prohibited-group,
      .prohibited-group-2 {
        display: none;
      }
    }
  }

  .list-group {
    position: relative;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #e2e4e8;
    padding: 30px 50px;
    border-radius: 16px;
    width: 70%;
    margin: 30px auto 0;

    @include respond($desktop_minimum) {
      width: 90%;
    }

    @include respond(600px) {
      width: 100%;
      padding: 25px 10px 15px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding-left: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    font-weight: 500;
    text-align: left !important;

    @include respond(600px) {
      font-size: 18px;
      line-height: 28px;
      list-style-type: none;
    }
  }

  .services-tab {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e2e4e8;
    border-radius: 50px;
    padding: 10px 20px;
    width: 35%;
    margin: 50px auto 0;

    .service {
      font-size: 24px;
      font-weight: 500;
      padding: 15px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.active {
        background-color: #22428f;
        color: #fff;
        border-radius: 50px;
      }
    }

    @include respond(1200px) {
      width: 50%;
    }

    @include respond(850px) {
      width: 80%;
    }
    @include respond(580px) {
      width: 100%;
      padding: 10px 10px;

      .service {
        font-size: 16px;
        padding: 10px 20px;
      }
    }
  }

  .list-container {
    display: flex;
    justify-content: space-between;

    @include respond(500px) {
      display: block;
    }
  }
}
