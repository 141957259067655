$tablet_minimum_index: 711px;
$tablet_minimum: 712px;
$tablet_maximum: 768px;
$desktop_minimum: 1024px;
$desktop_average: 1350px;
.lsp {
  .header {
    margin-top: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 5rem;
    line-height: 58px;
    text-align: left;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #22428f;
    @include respond($desktop_average) {
      font-size: 40px;
      line-height: 50px;
    }
    @include respond($tablet_maximum) {
      font-size: 3.5rem;
      line-height: 40px;
    }
  }

  p {
    font-size: 2rem;
    line-height: 32px;
    font-weight: 400;
    margin: 2rem 0 0;
  }

  .access-button {
    background: #ffd708;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #224391;
    margin-top: 27px;
    text-decoration: none;
  }

  &__intro {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 170px 6.675% 0 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 100px 6.675% 0 6.675%;
    }

    p {
      padding-right: 17%;
      @include respond($desktop_minimum) {
        padding-right: 0;
      }
    }

    .intro-content {
      width: 50%;
      text-align: left;
      padding-right: 4rem;
      padding-bottom: 65px;
      @include respond($desktop_minimum) {
        width: 100%;
        padding-right: 0;
        margin-top: 0 !important;
        padding-bottom: 65px !important;
      }
      @include respond($desktop_minimum) {
        width: 100%;
        padding-right: 0;
        margin-top: 0 !important;
        padding-bottom: 45px !important;
      }
    }

    .image-container {
      width: 45%;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      @include respond(1234px) {
        width: 50% !important;

        img {
          object-fit: fill;
        }
      }

      @include respond($desktop_minimum) {
        width: 70% !important;
        margin: 20px auto 0;

        img {
          object-fit: contain;
        }
      }

      @include respond(600px) {
        width: 100% !important;
      }
    }
  }

  &__price-control {
    background-color: #f6f9ff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 6.675% 0 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column-reverse;
      padding: 50px 6.675% 0 6.675%;
    }

    .price-content {
      width: fit-content;

      text-align: left;
      padding-left: 8rem;

      @include respond($desktop_minimum) {
        padding-left: 0;

        h1 {
          text-align: center;
        }
        a {
          margin: 20px auto;
        }
      }
    }

    .image-container {
      width: 40%;
      @include respond($desktop_minimum) {
        width: 70%;
        margin-top: 30px;
      }
      @include respond($tablet_minimum) {
        width: 100%;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__tracking {
    background-color: #22428f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 6.675% 0 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 50px 6.675% 0 6.675%;
    }

    .tracking-content {
      width: fit-content;
      text-align: right;
      padding-right: 10rem;
      h1 {
        text-align: right;
        color: #fff;
      }
      @include respond($desktop_minimum) {
        padding-right: 0;
        h1 {
          text-align: center;
        }
        a {
          margin: 20px auto;
        }
      }
    }

    .image-container {
      width: 45%;
      @include respond($desktop_minimum) {
        width: 70%;
        margin-top: 30px;
      }
      @include respond($tablet_minimum) {
        width: 100%;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__next-step {
    position: relative;
    background-color: #22428f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 120px 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 100px 6.675%;
    }

    .next-step-content {
      width: 50%;
      text-align: left;

      @include respond(1219px) {
        width: 60%;
        padding-right: 0;
      }

      @include respond($desktop_minimum) {
        width: 100%;
        padding-right: 0;
      }

      h1 {
        color: #fff;
        margin-top: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 4rem;
        line-height: 48px;
        text-align: left;
        letter-spacing: -0.02em;
        background-color: transparent;
        padding: 0;
        @include respond(1219px) {
          font-size: 3.4rem;
          line-height: 46px;
        }
        @include respond($desktop_minimum) {
          text-align: center;
        }
        @include respond($tablet_maximum) {
          font-size: 3.2rem;
          line-height: 40px;
        }
      }

      .small-text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        text-transform: uppercase;
        letter-spacing: 0.16em;
        text-align: left;
        color: #ffd708;
        @include respond($desktop_minimum) {
          text-align: center;
        }
        @include respond($tablet_maximum) {
          font-size: 12px;
        }
      }
    }

    .btn-container {
      a {
        width: 315px;
        height: 84px;

        background: #ffd708;
        border-radius: 10px;
        font-size: 32px;
        font-weight: 600;

        display: flex;
        justify-content: center;
        align-items: center;
        color: #224391;
        text-decoration: none;
      }
      @include respond(1219px) {
        a {
          width: 280px;
          height: 70px;
          font-size: 24px;
        }
      }
      @include respond($desktop_minimum) {
        a {
          margin-top: 50px;
        }
      }
      @include respond($tablet_minimum) {
        width: 90%;
        display: block;

        a {
          width: 100%;
          margin-top: 50px;
          width: 100%;
          height: 60px;
          font-size: 20px;
        }
      }
    }
  }

  .break-line {
    @include respond($desktop_minimum) {
      display: none;
    }
  }
  .banner-mobile {
    display: none;
  }
  @include respond($tablet_minimum) {
    width: 100%;
    .banner-web {
      display: none;
    }
    .banner-mobile {
      display: block;
    }
  }
}
