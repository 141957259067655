.exports {
  font-family: 'General Sans', sans-serif;

  .web-img {
    display: block;
    width: 100%;
    object-fit: contain;
    margin: 0 auto;

    @include respond(1000px) {
      display: none;
    }
  }
  .mobile-img {
    display: none;
    width: 100%;
    object-fit: contain;
    margin: 0 auto;

    @include respond(1000px) {
      display: block;
    }
  }
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 68px;
    text-align: center;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #000000;
    width: 85%;
    margin: 24px auto;
    position: relative;

    @include respond(1390px) {
      width: 90%;
    }
    @include respond($desktop_average) {
      font-size: 40px;
      line-height: 54px;
    }
    @include respond($tablet_maximum) {
      font-size: 34px;
      line-height: 48px;
      width: 100%;
    }
    @include respond(600px) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 54px;
    // text-align: left;
    letter-spacing: -0.02em;
    padding: 0;
    color: #000000;
    width: 50%;

    @include respond(1100px) {
      width: 70%;
    }
    @include respond(1000px) {
      font-size: 32px;
      line-height: 48px;
    }
    @include respond(700px) {
      font-size: 24px;
      line-height: 34px;
      width: 100%;
    }
  }

  &__terms-condition {
    padding: 100px 14.675% 100px 14.675%;
    @include respond($desktop_minimum) {
      padding: 50px 6.675%;
    }

    h2 {
      width: 100% !important;
      color: #22428f !important;
      text-align: center !important;
      display: flex;
      justify-content: center;
    }

    .china-conditions {
      border: 1px solid #e2e4e8;
      box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      margin: 30px 0;
      padding: 30px;

      .condition-item {
        padding-right: 0 !important;
        display: flex;
        align-items: flex-start;
      }
      @include respond(600px) {
        padding: 16px;

        .condition-item {
          flex-direction: column;
        }
      }

      .item-text {
        font-size: 24px !important;
        line-height: 32px;
        margin-left: 20px;
        color: #545859;

        @include respond(600px) {
          font-size: 16px !important;
          line-height: 26px;
          margin-left: 0;
          margin-top: 20px;
        }
      }

      .index {
        border-radius: 50%;
        border: 1px solid #e2e4e8;

        padding: 10px 20px;

        font-size: 28px;
        font-weight: 700;
        color: #22428f;
        margin-right: 5px;

        @include respond(600px) {
          font-size: 20px;
          margin-right: 0;
          padding: 5px 15px;
        }
      }
    }

    .conditions {
      height: fit-content;
      border: 1px solid #e2e4e8;
      box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      margin: 30px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      align-items: flex-start;
      padding: 30px;

      @include respond(789px) {
        display: block;
        padding: 30px 10px;
      }
    }

    .condition-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 40px;

      @include respond(600px) {
        margin-bottom: 20px;
      }
      text-align: left;
      padding-right: 40px;

      img {
        padding-right: 20px;
      }
    }

    .item-title {
      font-size: 24px;
      font-weight: 700;
      color: #0f1414;
      margin-bottom: 20px;
    }

    .item-text {
      font-size: 15px;
      line-height: 24px;
      color: #545859;
      font-weight: 500;
    }

    .cancellation {
      width: 100%;
      background-color: rgba(239, 165, 22, 0.1);
      display: flex;
      text-align: left;
      align-items: flex-start;
      padding: 30px;
      border-radius: 8px;

      img {
        padding-right: 10px;
      }

      p {
        font-size: 20px;
        line-height: 32px;
        color: #0f1414;
        margin: 0;
      }

      @include respond(600px) {
        padding: 20px 10px;

        p {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }

  &__flexible-options {
    background: linear-gradient(
      180deg,
      rgba(228, 238, 252, 0) 3.12%,
      #e4eefc 14.58%,
      rgba(228, 238, 252, 0.89576) 74.48%,
      rgba(228, 238, 252, 0.39) 97.92%
    );
    padding: 100px 6.675% 100px 6.675%;
    @include respond($desktop_minimum) {
      padding: 100px 6.675% 50px 6.675%;
    }
    @include respond(500px) {
      padding: 100px 6.675% 50px 6.675%;
    }
    .intro-wrap {
      width: 50%;
      margin: 0 auto;
      text-align: center;

      @include respond(1000px) {
        width: 80%;
      }

      .text-with-img {
        background-image: url('../../assets/svgs/china-gateway.svg');
        background-size: 100%;
        width: 400px;
        background-repeat: no-repeat;
      }
      .safety-pin {
        width: 94%;
        margin: auto;
        display: block;

        @include respond(600px) {
          display: none;
        }
      }
      .safety-pin-mobile {
        width: 100%;
        display: none;

        @include respond(600px) {
          display: block;
        }
      }

      p {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        color: #0f1414;
        white-space: nowrap;
        margin: 20px 0 30px;

        @include respond(1200px) {
          white-space: normal;
        }
      }
    }
  }

  &__package-options {
    background-color: #fff;
    padding: 100px 6.675% 100px 6.675%;
    @include respond($desktop_minimum) {
      padding: 50px 6.675% 100px 6.675%;
    }

    .hide-title {
      @include respond(700px) {
        display: none;
      }
    }
    .package-switch {
      display: flex;
      justify-content: center;
      width: fit-content;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 50px;
      height: 74px;
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3 ease;
      border: 1px solid #e2e4e8;

      @include respond(700px) {
        display: none;
      }

      .package-switch-item {
        transition: all 0.3s ease-in;
        font-size: 18px;
        font-weight: 600;
        border-radius: 50px;
        padding: 16px 28px;
        color: #545859;
        background-color: #fff;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.3 ease;

        @include respond(900px) {
          padding: 16px;
        }

        &.isActive {
          color: #fff;
          background-color: #22428f;
        }
      }
    }

    .card-container {
      transition: all 0.3s ease-in;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4%;
      margin-top: 50px;

      @include respond(1195px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(700px) {
        display: block;
      }
    }

    .package-card {
      padding: 30px 40px;
      border-radius: 24px;
      background-color: #fff;
      border: #e2e4e8 1px solid;
      color: #0f1414;
      text-align: left;

      @include respond(700px) {
        margin-bottom: 40px;
        padding: 20px;
      }

      &.isBudget {
        background-color: #22428f;
        border: #22428f 1px solid;

        color: #fff !important;
      }

      .title {
        font-size: 22px;
        color: #000000;
        font-weight: 700;
        white-space: nowrap;
        &.isBudget {
          color: #fff !important;
        }
      }
      .from {
        font-size: 16px;
        font-weight: 600;
        color: #545859;
        &.isBudget {
          color: #fff !important;
        }
      }

      .recommended {
        border-radius: 50px;
        background-color: rgba(214, 226, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
        padding: 8px 14px;
        font-weight: 600;
        width: fit-content;
        white-space: nowrap;
      }

      .amount-div {
        font-size: 38px;
        font-weight: 800;

        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .package-card:nth-child(4) {
      grid-row: 2;
      grid-column: 2 / 3;
      margin-top: 20px;

      @include respond(1150px) {
        grid-row: 2;
        grid-column: 1;
        margin-top: 0;
      }
    }
    .package-list {
      padding-left: 0;
      margin-top: 40px;
      list-style: none;

      li {
        font-size: 14px;
        font-weight: 500;
        color: #0f1414;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        &.isBudget {
          color: #fff !important;
        }

        p {
          padding-left: 6px;
          line-height: 20px;
        }
      }
    }

    .ship-now {
      margin-top: 40px;
      border: 1px solid #ccc;
      border-radius: 6px;
      display: flex;
      width: 100%;
      height: 56px;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: 600;
      color: #22428f;
      text-decoration: none;

      &.isBudget {
        margin-top: 10px;
        background-color: #ffd708;
        border: none !important;
      }
    }
    .absolute-position {
      position: absolute;
      bottom: 30px;
      left: 20%;
      z-index: 50;
      width: 450px;

      @include respond(500px) {
        width: 100%;
        left: unset;
      }
    }

    .poly-icon {
      padding-left: 10%;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include respond(700px) {
        padding-left: 20%;
      }

      @include respond(500px) {
        padding-left: 40%;
      }
    }

    .how-it-works {
      cursor: pointer;
      position: relative;
      margin-top: 40px;
      border: 1px solid #fff;
      border-radius: 6px;
      border-radius: 6px;
      display: flex;
      width: 100%;
      height: 56px;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: 600;
      color: #fff;
      text-decoration: none;
      background-color: #22428f;
      text-align: center;
    }

    .how-guide {
      background-color: #fff;
      color: #545859;
      width: 100%;
      border-radius: 12px;
      padding: 30px 20px;
      border: 1px solid #e2e4e8;
      box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    }

    .rate-wrap {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      border: 1px solid rgb(226, 228, 232);
      border-radius: 6px;

      @include respond(500px) {
        display: block;
      }
    }

    .shipping-text {
      color: #545859;
      font-size: 14px;
      line-height: 26px;
      font-weight: 600;
      text-align: left;
    }

    .web-version {
      display: block;

      @include respond(700px) {
        display: none;
      }
    }
    .mobile-version {
      display: none;

      @include respond(700px) {
        display: block;
        .label {
          display: block;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
  }
  &__shipping-option {
    background: linear-gradient(
      180deg,
      rgba(228, 238, 252, 0) 3.12%,
      #e4eefc 14.58%,
      rgba(228, 238, 252, 0.89576) 74.48%,
      rgba(228, 238, 252, 0.39) 97.92%
    );
    padding: 0px 6.675% 100px 6.675%;
    @include respond($desktop_minimum) {
      padding: 50px 6.675% 50px 6.675%;
    }

    .benefit-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1%;
      margin-top: 50px;

      @include respond(1150px) {
        gap: 2%;
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(700px) {
        display: block;
      }
    }

    .benefit-item {
      border: 1px solid #e2e4e8;
      padding: 14px;
      background-color: #fff;
      text-align: left;
      font-size: 18px;
      color: #000000;
      font-weight: 500;
      border-radius: 5px;
      @include respond(1150px) {
        margin-bottom: 10px;
      }
      @include respond(700px) {
        font-size: 16px;
      }
    }
  }

  .broker-text {
    width: 60% !important;
  }

  .broker-container {
    position: relative;
    z-index: 0;
    height: fit-content;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    width: 74%;
    margin: 0 auto;
    padding: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 40px;

    .broker-item {
      width: 96%;
      font-size: 16px !important;
      line-height: 24px;
      text-align: left;
      font-weight: 500;
    }

    a,
    span {
      font-weight: 600;
      padding-left: 6px;
      color: #22428f;
      text-decoration: none;
    }
  }

  .broker-wrap {
    position: relative;
    border: 1px solid #e2e4e8;
    border-radius: 50px;
    width: 14%;
    margin: 0 auto;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // cursor: pointer;
  }

  .country-popup {
    position: absolute;
    margin-top: 30px;
    z-index: 50;
    top: 50px;
    left: 0;
    background: #fff;
    border: 1px solid #e2e4e8;
    border-radius: 6px;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
    padding: 20px;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  @include respond(1000px) {
    .broker-text {
      width: 100%;
    }
    .broker-container {
      display: block;
      width: 100%;
      padding: 30px;
    }

    .broker-wrap {
      width: 60%;
    }

    .broker-item {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  .budget-country-lists {
    position: relative;
    border: 1px solid #e2e4e8;
    border-radius: 50px;
    width: 300px;
    margin: 10px 0;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // cursor: pointer;
  }
}
