.press {
  &__background {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;

    @include respond(tablet) {
      margin-top: 3rem !important;
    }
    &__cover {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.602);
    }
    & > div {
      width: 100%;
    }

    & h1 {
      position: absolute;
      color: white;
      font-weight: 600;
      z-index: 1000;
    }
  }

  &__intro {
    background-color: #e8e8e8;
    padding: 9vw 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      font-size: 1.3rem;
      line-height: 2rem;
      max-width: 62rem;
      text-align: center;
    }
  }

  &__publications {
    margin-bottom: 3rem;
    & > p {
      width: 35rem;
      margin: auto;
      line-height: 2.5rem;
      font-size: 1.5rem;
      margin-top: 5rem;
      text-align: center;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
      grid-auto-flow: row;
      grid-row-gap: 4rem;
      grid-column-gap: 2rem;
      padding: 7rem 4rem;

      & > a {
        text-decoration: none;

        &:hover {
          & p {
            color: var(--color-blue-dark) !important;
            text-decoration: underline;
          }
        }
      }

      @include respond(small) {
        grid-template-columns: repeat(2, 1fr);
        align-items: start;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include respond(tablet) {
        & > div {
          transform: scale(0.8);
        }
      }

      & p {
        max-width: 30rem;
        text-align: center;
        font-size: 1.2rem;
        margin-top: 2rem;
        line-height: 2.5rem;
        font-weight: 500;
        color: black;
      }
    }
  }

  &__subscription {
    position: relative;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateY(1.32rem);

    @include respond(tablet) {
      transform: translateY(1.37rem);
      & form {
        grid-template-columns: 1fr 0.5fr;
        width: 90%;
        overflow: unset;
        align-self: center;
        margin: auto;
        margin-top: 2rem;

        & input {
          width: 100% !important;
        }
      }
    }

    & figure {
      margin: 0 !important;

      & > div {
        width: 100vw;

        @include respond(tablet) {
          height: 30rem;
        }
      }
    }

    &__content {
      position: absolute;
      text-align: center;

      & h2,
      & p {
        color: white;
      }

      @include respond(tablet) {
        & h2,
        & p {
          max-width: 90%;
          margin: auto;
          margin-top: 1.5rem;
        }

        & h2 {
          font-size: 2rem;
        }

        & p {
          font-size: 1.3rem;
        }
      }
    }
  }
}
