.careers {
  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond(tablet) {
      margin-top: 3rem;
    }
    & > div {
      width: 100%;
    }

    &__background {
    }

    & h1 {
      position: absolute;
      top: 10rem;
      left: 4rem;
      color: white;
      padding-bottom: 2rem;
      border-bottom: 1px solid white;
      min-width: 75%;
    }

    @include respond(tablet) {
      & h1 {
        position: absolute;
        top: unset;
        left: unset;
        border-bottom: none;
        min-width: unset;
      }
    }
  }

  & button {
    padding: 1.5rem 7rem !important;
    border-radius: 0.5rem;
  }

  &__paragraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    padding-top: 5rem;
    padding-bottom: 7rem;

    & h1 {
      color: var(--color-blue-dark);
      font-size: 3rem;

      @include respond(tablet) {
        font-size: 2rem;
      }
    }
    & p {
      max-width: 80rem;
      font-size: 1.5rem;
      line-height: 2.5rem;
      font-weight: 400;
      text-align: center;
      margin-top: 3rem;
    }

    & > figure {
      padding-left: 15vw;
      margin-top: 4rem;

      @include respond(tablet) {
        transform: scale(1.2);
      }
    }

    &__photos {
      width: 60vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 1.5rem;
      padding: 4rem;

      & figure {
        & > div {
          width: 100%;
          height: 100%;
        }
      }

      & figure:nth-child(1) {
        grid-row: 1 / 3;
        grid-column: 1 / 2;
      }
      & figure:nth-child(2) {
        grid-row: 1 / 2;
        grid-column: 2 / -1;
      }
      & figure:nth-child(3) {
        grid-row: 2 / 3;
        grid-column: 2 / -1;
      }
      & figure:nth-child(4) {
        grid-row: 3 / 4;
        grid-column: 1 / -1;
      }

      @include respond(tablet) {
        width: 100%;
        padding: 4rem 2rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        & figure:nth-child(1) {
          display: none;
        }
        & figure:nth-child(2) {
          grid-row: 1 / -1;
          grid-column: 1 / -1;
          transform: scale(1.1);
        }
        & figure:nth-child(3) {
          display: none;
        }
        & figure:nth-child(4) {
          display: none;
        }
      }
    }
  }
}

.jobs__list {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-column-gap: 5rem;
  width: 70vw;
  margin: auto;
  margin-top: 10vw;
  margin-bottom: 10vw;

  @include respond(smaller) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
  }

  &__search {
    display: flex;
    flex-direction: column;
    padding: 3.5vw 3.5vw 8rem 3.5vw;
    border-radius: 0.7rem;
    background-color: #d1d1d1;

    @include respond(smaller) {
      width: 100%;
      padding: 9rem 4rem;
    }

    & button {
      background-color: var(--color-blue-dark);
      color: white;
      font-size: 1.5rem;
      padding: 1.5rem;
      margin-top: 8rem;
      border-radius: 0.7rem;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  &__items {
    padding: 0 5rem;

    &__item {
      display: none;
    }

    @include respond(smaller) {
      padding: 0;
      margin-top: 4rem;
      margin-bottom: 70rem;
      align-self: flex-start;
      &__item {
        display: block;
      }
    }

    &__item {
      & h3 {
        font-size: 2.1rem;
      }

      & p {
        font-size: 1.7rem;
        color: gray;
      }

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    & table {
      @include respond(smaller) {
        display: none;
      }
      & thead {
        background-color: #d1d1d1;
        font-size: 1.8rem;

        & tr {
          border-top-left-radius: 6px;
        }

        & th {
          padding: 2rem;
          font-weight: 600;
        }
      }

      & tbody {
        font-size: 1.5rem;

        & tr {
          border-top-right-radius: 0.7rem !important;
        }

        & td {
          padding: 2rem;
        }
      }
    }
  }
}
