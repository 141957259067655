$tablet_minimum_index: 711px;
$tablet_minimum: 712px;
$tablet_maximum: 768px;
$desktop_minimum: 1024px;
$desktop_average: 1350px;

.get-help-form {
  &__contact-form {
    padding: 100px 6.675% 100px 6.675%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    position: relative;

    &.blue-bg {
      background: #f6f9ff;
    }

    @include respond(1000px) {
      flex-direction: column;
      padding: 50px 6.675% 50px 6.675%;
    }

    .pointer-div {
      height: 200px;
      width: 200px;
      position: absolute;
      top: 50%;
      left: 40%;
      transform: translate(-50%, -40%);

      @include respond(1000px) {
        display: none;
      }
    }

    .lets-chat {
      width: 56%;

      @include respond(1000px) {
        width: 70%;
      }
      @include respond(600px) {
        width: 100%;
      }
      .heading {
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 3.2rem;
        letter-spacing: -0.02em;
        color: #22428f;
        text-align: left;
        @media screen and (min-width: 48rem) {
          font-weight: 600;
          font-size: 4.8rem;
          line-height: 5rem;
          color: #22428f;
          text-align: left;
        }
        // @include respond(600px) {
        //   font-size: 28px;
        //   line-height: 30px;
        // }
      }
      .paragraph {
        font-size: 18px;
        color: #545859;
        line-height: 22px;
        font-weight: 500;
        text-align: left;

        @include respond(1100px) {
          font-size: 16px;
        }
      }

      .contact-support {
        border: #e2e4e8 1px solid;
        background-color: #fff;
        color: #22428f;
        width: fit-content;
        font-weight: 700;
        font-size: 20px;
        padding: 26px;
        border-radius: 12px;
      }
    }
    .form-container {
      width: 44%;
      margin: auto;
      border: 1px solid #ddd;
      background-color: white;
      padding: 40px 30px;
      border-radius: 12px;

      @include respond(1000px) {
        width: 70%;
        margin: 50px 0 0;
      }
      @include respond(789px) {
        width: 100%;
      }
    }

    .form-row {
      display: flex;
      justify-content: space-between;
      gap: 2%;

      @include respond(1090px) {
        flex-direction: column;
      }
    }

    .form-group {
      width: 100%;
      margin-bottom: 20px;
    }

    & label {
      display: block;
      font-size: 1.4rem;
      margin-bottom: 1rem;
      color: black;
      font-weight: 400;
      text-align: left;
    }

    & input,
    & textarea {
      padding: 1.4rem;
      width: 100%;
      background-color: white;
      font-size: 1.5rem;
      color: #6b6b6b;
      border: 1px solid #e7e4e4;
      border-radius: 12px;
      padding: 1.5rem 0.7rem;

      &:focus {
        outline: none;
      }
    }

    button {
      border: 1px solid #22428f;
      padding: 26px 20px !important;
    }
  }
}
