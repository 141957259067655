$tablet_minimum_index: 711px;
$tablet_minimum: 712px;
$tablet_maximum: 768px;
$desktop_minimum: 1024px;
$desktop_average: 1200px;
$desktop_maximum: 1270px;
.shopnship {
  .header {
    margin-top: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 5rem;
    line-height: 58px;
    text-align: left;
    display: block;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #22428f;
    @include respond($desktop_maximum) {
      font-size: 40px;
      line-height: 50px;
    }
    @include respond($tablet_maximum) {
      font-size: 3.5rem;
      line-height: 40px;
    }
  }

  .access-button {
    background: #ffd708;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    width: 200px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #224391;
    margin-top: 27px;
    text-decoration: none;
  }

  &__intro {
    position: relative;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 170px 6.675% 0 6.675%;
    height: 88vh;
    @include respond($desktop_average) {
      height: 88vh;
    }
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 100px 0 0;
      height: auto;
    }

    p {
      font-size: 2rem;
      line-height: 32px;
      font-weight: 400;
      margin: 2rem 0 0;
      padding-right: 17%;
      @include respond($tablet_maximum) {
        padding-right: 0;
      }
    }

    .intro-content {
      width: 50%;
      text-align: left;
      @include respond(1470px) {
        width: 55%;
      }
      @include respond($desktop_minimum) {
        width: 60%;
      }
      @include respond($desktop_minimum) {
        width: 100%;
        padding: 0 6.675%;
        margin-bottom: 30px;
      }
    }

    .image-container {
      position: absolute;
      width: 65%;
      bottom: 0;
      right: 0;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      @include respond($desktop_maximum) {
        width: 70%;
      }
      @include respond($desktop_minimum) {
        width: 100%;
        position: relative;
      }
    }
  }
  &__features {
    background: #f6f9ff;
    padding: 128px 6.675%;
    @include respond(mini-mobile) {
      padding: 64px 6.675%;
    }
    // padding: 128px 96px;
  }
  &__feature {
    background: #ffffff;
    box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    height: 300px;
    @media (max-width: 1350px) {
      height: unset;
    }
  }
  &__features__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2%;
    row-gap: 40px;
    @media screen and (min-width: $tablet_minimum) and (max-width: 1350px) {
      grid-template-columns: 1fr 1fr;
    }
    @include respond($tablet_minimum_index) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
  &__feature__wrap {
    padding: 10.125%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    // padding: 40px 40px 0 40px;
  }
  &__features__container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 64px;
  }
  &__features__question * {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.16em;
    text-align: left;
    color: #22428f;
  }
  &__feature__icon * {
    margin-bottom: 24px;
  }
  &__feature__header * {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #0f1414;
    margin-bottom: 16px;
  }
  &__feature__content p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #545859;
    text-align: left;
  }
  .break-line {
    @include respond(1350px) {
      display: none;
    }
  }
}
