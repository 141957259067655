.nav {
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;

  @include respond(mobile) {
    padding: 20px;
  }

  &__logo__desktop {
    cursor: pointer;

    @include respond(mobile) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @include respond(mobile) {
      display: block;
      width: 100%;
    }
  }

  &__mobile__top_content {
    display: flex;
    justify-content: space-between;
  }

  &__mobile__bottom_content {
    cursor: pointer;

    .buttons {
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 4px;
      margin: 5px 0px 0px 0px;
    }
  }

  &__sign_in {
    margin-right: 4rem;
    font-weight: 600;
    font-size: 1.35rem;
    text-decoration: none;
    color: var(--color-black);

    &:hover {
      color: var(--color-black);
    }
  }

  &__book_shipment {
    font-weight: 600;
    font-size: 1.35rem;
    text-decoration: none;
    color: var(--color-white);
    background-color: var(--color-blue-dark);
    padding: 10px 15px;
    border-radius: 20px;

    &:hover {
      color: var(--color-white);
    }
  }

  &__desktop {
    display: block;

    @include respond(mobile) {
      display: none;
    }
  }

  &__login {
    background-color: white !important;
    border: 1px solid var(--color-blue-dark) !important;
    color: var(--color-blue-dark) !important;
  }

  &__signups {
    background-color: var(--color-blue-dark) !important;
    border: 1px solid var(--color-blue-dark) !important;
    color: white !important;
  }

  &__quotes {
    text-align: left;

    &__header {
      color: var(--color-blue-dark);
      font-size: 3.5rem;
      font-weight: bolder;
    }

    &__header-description {
      font-size: 1.8rem;
    }
  }
}
