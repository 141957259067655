$desktop-medium: 1350px;
$desktop-small: 1240px;
$tablet-large: 1000px;
$tablet-medium: 712px;

.china-imports {
  .heading {
    font-weight: 600;
    font-size: 40px;
    line-height: 58px;
    letter-spacing: -0.02em;
    color: #000000;
    text-align: left;
    @include respond($desktop_average) {
      font-size: 32px;
      line-height: 48px;
    }

    @include respond(600px) {
      font-size: 28px;
      line-height: 30px;
    }
  }
  .paragraph {
    font-size: 18px;
    color: #545859;
    line-height: 28px;
    font-weight: 500;
    text-align: left;

    @include respond(1100px) {
      font-size: 16px;
    }
  }

  &__intro {
    background-image: url('../../assets/images/china-bg.png');
    // height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    &__wrap {
      padding: 50px 6.675% 50px 6.675%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      @include respond($desktop_minimum) {
        padding: 120px 6.675% 0 6.675%;
      }

      @include respond(900px) {
        flex-direction: column;
      }

      .content-wrap {
        width: 40%;
        padding-top: 100px;

        span {
          background-image: url('../../assets//svgs/china-gateway.svg');
          background-size: 100%;
          background-repeat: no-repeat;
        }

        @include respond(900px) {
          width: 100%;
          padding-top: 0;
        }
      }

      img {
        width: 50%;
        height: 50vh;

        @include respond(1068px) {
          height: 44vh;
          margin-top: 20px;
        }

        @include respond(900px) {
          width: 100%;
          height: auto;
          margin-top: 30px;
        }
      }

      .cta-group {
        display: flex;
        gap: 4%;
      }

      button,
      a {
        font-weight: 700;
        font-size: 16px;
        padding: 18px 30px;
        border-radius: 12px;
        width: fit-content;
        white-space: nowrap;

        @include respond(900px) {
          font-size: 14px;
          padding: 18px 20px;
        }
      }
      .contact-support {
        border: #e2e4e8 1px solid;
        background-color: #fff;
        color: #22428f;
        text-decoration: none;
      }

      .start-shipping {
        border: #22428f 1px solid;
        background-color: #22428f;
        color: #fff;
        border-radius: 12px;
      }
    }
  }

  &__pricing {
    &__wrap {
      padding: 64px 6.94vw 40px 6.94vw;
      @include respond($tablet-large) {
        padding: 64px 24px 40px 24px;
      }
    }
    &__header h1 {
      color: #000;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: General Sans;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 40px */
      letter-spacing: -0.8px;
    }
    &__items {
      margin: 40px 0;
      &__wrap {
        display: flex;
        flex-direction: row;
        gap: 24px;
        flex-wrap: wrap;
        justify-content: center;
        @include respond($desktop-medium) {
          display: grid;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        }
        @include respond($tablet-medium) {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &__item {
      width: 27.4vw;
      border-radius: 24px;
      background: #fff;
      border: 1px solid #e2e4e8;
      @include respond($desktop-medium) {
        width: 100%;
      }
      &__wrap {
        text-align: left;
        padding: 40px 20px;
      }
      &__header {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: General Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 113%; /* 27.12px */
        letter-spacing: -0.48px;
        text-align: left;
        margin-bottom: 24px;
      }
      &__text {
        display: block;
        color: #545859;
        font-family: General Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
      }
      &__content {
        color: #000;
        font-family: General Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
      &__price {
        color: #000;
        font-family: General Sans;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
      }
      &__features {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }
      &__feature {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        &__main {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        span {
          color: var(--topship-colors-black-primary, #0f1414);
          /* General/Md/16 */
          font-family: General Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%; /* 25.6px */
        }
        &__notice {
          border-radius: 300px !important;
          background: var(--Topship-blue-100, #e5edff) !important;
          padding: 4px 8px !important;
          color: var(--Topship-Brand-Blue, #22428f) !important;
          font-family: 'General Sans' !important;
          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          line-height: 160% !important;
        }
      }
      &__action {
        margin-top: 40px;
        padding: 12px 24px;
        border-radius: 8px;
        background: white;
        width: 100%;
        border: 2px solid #e2e4e8;
        color: #22428f;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: General Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 22.4px */
      }
    }
    &__alert {
      border-radius: 12px;
      border: 1px solid #e2e4e8;
      &__wrap {
        display: flex;
        align-items: flex-start;
        padding: 40px 32px;
        gap: 5px;
        @include respond($tablet-large) {
          padding: 27px 16px;
        }
      }
      &__content span {
        text-align: left;
        display: block;
        color: #22428f;
        font-family: General Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 33.6px */
        @include respond($tablet-large) {
          font-size: 16px;
        }
      }
    }
  }
  &__how-it-works {
    background: linear-gradient(
      180deg,
      rgba(228, 238, 252, 0) 3.12%,
      #e4eefc 14.58%,
      rgba(228, 238, 252, 0.9) 74.48%,
      rgba(228, 238, 252, 0.39) 97.92%
    );

    &__wrap {
      padding: 80px 11.32vw;
      @include respond($tablet-large) {
        padding: 40px 24px;
      }
    }

    &__header h1 {
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: General Sans;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 40px */
      letter-spacing: -0.8px;
    }

    &__items {
      display: grid;
      margin-top: 40px;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      @include respond($tablet-large) {
        display: flex;
        flex-direction: column;
      }
    }

    &__item {
      border-radius: 8px;
      border: 1px solid var(--topship-colors-input-outline, #e2e4e8);
      background: #fff;
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        gap: 16px;
        @include respond($desktop-small) {
          padding: 40px 16px;
        }

        @include respond($tablet-large) {
          padding: 24px 8px;
        }
      }
      &__header h2 {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: General Sans;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 127%; /* 30.48px */
        letter-spacing: -0.48px;
      }
      &__content {
        color: var(--topship-colors-black-secondary, #545859);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: General Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 127%; /* 20.32px */
        letter-spacing: -0.32px;
      }
    }
  }
  &__faq {
    background: linear-gradient(
      180deg,
      rgba(228, 238, 252, 0) 3.12%,
      #e4eefc 14.58%,
      rgba(228, 238, 252, 0.89576) 74.48%,
      rgba(228, 238, 252, 0.39) 97.92%
    );
    @include respond($tablet-large) {
      padding-bottom: 20px;
    }
    padding-bottom: 50px;
    &__wrap {
      display: flex;
      flex-direction: column;
      padding: 106px 6.94vw 0 6.94vw;
      margin-bottom: 80px;
      gap: 48px;
      @include respond($tablet-large) {
        padding: 90px 24px 0 24px;
      }
    }
    &__header h1 {
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: General Sans;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 40px */
      letter-spacing: -0.8px;
      text-align: left;

      @include respond(600px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    &__features {
      display: flex;
      align-items: center;
      gap: 24px;
      @include respond($tablet-large) {
        flex-direction: column;
      }
    }
    &__feature {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #e2e4e8;
      background: #fff;
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 16px;
        gap: 8px;
      }
      &__content span {
        color: #000;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: General Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 127%; /* 22.86px */
        letter-spacing: -0.36px;
        display: block;
        text-align: left;
      }
    }
  }
}
