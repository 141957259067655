.home {
  &__partnership {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7rem;

    &__texts {
      display: flex;
      flex-direction: column;
      align-items: center;
      & h2 {
        color: var(--color-blue-dark);
        font-weight: 600;
        font-size: 3.3rem;
        @include respond(mobile) {
          font-size: 2.5rem;
        }
      }

      & p {
        font-size: 2rem;
        font-weight: 500;
        color: var(--color-intro2);
        line-height: 3.2rem;
        width: 39rem;
        letter-spacing: 1px;
        text-align: center;

        @include respond(mobile) {
          font-size: 1.8rem;
          width: 90%;
        }
      }

      &__toggle {
        background-color: var(--color-yellow);
        padding: 0.6rem;
        border-radius: 0.7rem;
        margin-top: 1.3rem;
        &--active {
          background-color: var(--color-blue-dark);
          color: white !important;
        }

        & span {
          font-size: 1.2rem;
          min-width: 12rem;
          display: inline-block;
          cursor: pointer;
          padding: 0.5rem 3rem;
          border-radius: 0.7rem;
          color: var(--color-blue-dark);

          &:first-child {
            margin-right: 1.5rem;
          }
        }
      }
    }

    &__form {
      margin-top: 3rem;

      padding: 12rem 6rem 4rem 6rem;

      background-color: var(--color-form);
      width: 60vw;
      display: flex;
      flex-direction: column;

      @include respond(medium) {
        width: 90vw;
      }
      @include respond(mobile) {
        width: 95vw;
        padding: 6rem 2rem 4rem 2rem;
      }

      &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 4vw;
      }

      &__group {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        font-size: 2rem;
        color: var(--color-gray);
        margin-bottom: 3rem;

        @include respond(mobile) {
          font-size: 1.5rem;
        }

        & label {
          margin-bottom: 2rem;
        }

        & input {
          width: 100%;
          border: 1px solid #e7e4e4;
          border-radius: 0.5rem;
          padding: 1.5rem 0.7rem;
        }
      }

      & button {
        background-color: var(--color-blue-dark);
        color: white;
        border-radius: 0.8rem;
        font-size: 1.5rem;
        padding: 1.5rem 3rem;
        border: none;
        font-weight: 700;
        align-self: center;
      }
    }
  }
}
