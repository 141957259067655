.home {
  &__subscription {
    padding: 7rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50rem;
    margin: auto;

    @include respond(tablet) {
      max-width: 100%;
      padding: 7rem 2rem;
    }

    & h2 {
      font-size: 2.4rem;
      color: var(--color-blue-dark);
      font-weight: 500;
    }

    & p {
      font-size: 1.6rem;
      line-height: 2.5rem;
      margin-top: 1.5rem;
      text-align: center;
      font-weight: 400;
    }

    & form {
      display: grid;
      grid-template-columns: 1fr 0.3fr;
      margin-top: 3rem;
      border-radius: 0.5rem;
      overflow: hidden;
      width: 100%;
      & input {
        font-size: 1.7rem;
        padding: 1.5rem 3rem 1.5rem 1.5rem;
        border: none;
        background: #e3e3e3;

        &:focus {
          outline: none;
        }
      }

      & button {
        padding: 1.5rem;
        font-size: 1.5rem;
        border: none;
        background-color: var(--color-blue-dark);
        color: white;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
