.sea-freight {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 68px;
    text-align: center;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #fff;

    @include respond($desktop_average) {
      font-size: 40px;
      line-height: 54px;
    }
    @include respond($tablet_maximum) {
      font-size: 34px;
      line-height: 48px;
    }
    @include respond(600px) {
      font-size: 30px;
      line-height: 44px;
    }
  }
  &__header-intro {
    background-image: url('../../assets/images/sea-freight.png');
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;

    @include respond(900px) {
      background-image: url('../../assets/images/sea-freight-mobile.png');
    }

    .content {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      color: #fff;
    }

    .intro-wrap {
      padding: 240px 0;
      width: 35%;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include respond(900px) {
        width: 70%;
      }

      @include respond(500px) {
        width: 100%;
        padding: 170px 6.675% 0;
      }
    }
  }

  &__package-options {
    background-color: #fff;
    padding: 100px 6.675% 100px 6.675%;
    @include respond($desktop_minimum) {
      padding: 50px 6.675%;
    }
    .package-switch {
      display: flex;
      justify-content: center;
      width: fit-content;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 50px;
      height: 74px;
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3 ease;
      border: 1px solid #e2e4e8;

      .package-switch-item {
        transition: all 0.3s ease-in;
        font-size: 18px;
        font-weight: 600;
        border-radius: 50px;
        padding: 16px 28px;
        color: #545859;
        background-color: #fff;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.3 ease;

        @include respond(1100px) {
          padding: 16px;
        }

        @include respond(700px) {
          font-size: 15px;
        }

        &.isActive {
          color: #fff;
          background-color: #22428f;
        }
      }
    }

    .sub-heading {
      width: 30%;
      margin: 40px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      p {
        color: #22428f;
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
      }

      @include respond(1100px) {
        width: 70%;
        p {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
    .card-container {
      transition: all 0.3s ease-in;
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 50px;

      @include respond(700px) {
        display: block;
      }
    }

    .package-card {
      padding: 30px;
      border-radius: 24px;
      background-color: #fff;
      border: #e2e4e8 1px solid;
      color: #0f1414;
      text-align: left;
      width: 30%;

      @include respond(1100px) {
        width: 50%;
      }

      @include respond(700px) {
        margin-bottom: 40px;
        padding: 20px;
        width: 100%;
      }

      .title {
        font-size: 22px;
        color: #000000;
        font-weight: 700;
        white-space: nowrap;
        &.isBudget {
          color: #fff !important;
        }
      }
    }

    .package-list {
      padding-left: 0;
      margin-top: 40px;
      list-style: none;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        gap: 10px;

        p {
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
          color: #0f1414;
          margin-top: -2px;
        }
      }
    }

    .ship-now {
      margin-top: 40px;
      border: 1px solid #ccc;
      border-radius: 8px;
      display: flex;
      width: 100%;
      height: 56px;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: 700;
      color: #22428f;
      text-decoration: none;
    }
  }
}
