.footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 4.5vw 6vw;
  background-color: var(--color-blue-dark);
  @include respond(small) {
    padding: 7vw 3vw;
  }
  @include respond(smaller) {
    grid-template-columns: 1fr;
  }

  &__left {
    &__logo {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 3rem;

      & img {
        width: 12rem;
      }
    }
    &__contact {
      text-align: left;
      & p {
        font-size: 1.6rem;
        &:first-child {
          margin-top: 4rem 0;
          font-weight: 600;
          color: var(--color-yellow);
        }

        &:last-child {
          color: white;
          font-weight: 0;
        }
      }
    }

    &__socials {
      display: flex;
      margin-top: 3rem;
      &__icon {
        background-color: var(--color-blue-white);
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:last-child) {
          margin-right: 2rem;
        }

        padding: 1.5rem;

        & a > div {
          width: max-content;
        }

        & img {
          width: 1.5rem;
        }
        &--facebook img {
          width: 1rem;
        }
      }
    }
  }
  &__right {
    color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-left: 14vw;

    @include respond(smaller) {
      padding-left: 0;
      margin-top: 4rem;
    }

    &__group {
      margin-bottom: 1.5rem;

      & a {
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    & h3 {
      font-weight: 600;
    }

    & p {
      font-size: 1.3rem;
      @include respond(smaller) {
      }
    }
  }
}
