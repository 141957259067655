.resources {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 68px;
    text-align: left;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #000000;

    @include respond(1390px) {
      width: 90%;
    }
    @include respond($desktop_average) {
      font-size: 40px;
      line-height: 54px;
    }
    @include respond($tablet_maximum) {
      font-size: 34px;
      line-height: 48px;
      width: 100%;
    }
    @include respond(600px) {
      font-size: 30px;
      line-height: 44px;
    }
  }

  &__header-wrap {
    background-color: #f6f9ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 170px 6.675% 100px 6.675%;
    @include respond($desktop_minimum) {
      padding: 100px 6.675% 50px 6.675%;
    }
    @include respond(650px) {
      display: block;
    }
    p {
      color: #545859;
      text-align: left;
      font-size: 24px;
      font-weight: 500;

      @include respond(1128px) {
        font-size: 18px;
      }

      @include respond(650px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    img {
      width: 50%;

      @include respond(650px) {
        width: 100%;
      }
    }
  }
  &__resources-wrap {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 100px 6.675% 100px 6.675%;
    @include respond($desktop_minimum) {
      padding: 50px 6.675% 50px 6.675%;
      display: block;
    }

    p {
      color: #000000;
      text-align: left;
      font-size: 22px;
      font-weight: 500;

      @include respond(1128px) {
        font-size: 18px;
      }

      @include respond(650px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .resource-item {
      width: 49%;
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      @include respond($desktop_minimum) {
        width: 100%;
        padding: 16px;
      }
    }

    a {
      text-decoration: none;
    }

    .view {
      background-color: #22428f;
      color: #fff;
      padding: 10px 30px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 600;

      @include respond(650px) {
        font-size: 12px;
        padding: 10px 22px;
      }
    }
  }
}
