$tablet_minimum_index: 711px;
$tablet_minimum: 712px;
$tablet_maximum: 768px;
$desktop_minimum: 1024px;
$desktop_average: 1350px;
.api-documentation {
  .header {
    margin-top: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 5rem;
    line-height: 58px;
    text-align: left;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #22428f;
    @include respond($desktop_average) {
      font-size: 40px;
      line-height: 50px;
    }
    @include respond($tablet_maximum) {
      font-size: 3.5rem;
      line-height: 40px;
    }
  }

  p {
    font-size: 2rem;
    line-height: 32px;
    font-weight: 400;
    margin: 2rem 0 0;
  }

  .access-button {
    background: #224391;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    width: 255px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-top: 27px;
    text-decoration: none;
  }

  &__developers {
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 170px 6.675% 65px 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 120px 6.675% 50px 6.675%;
    }

    p {
      padding-right: 17%;
      @include respond($desktop_minimum) {
        padding-right: 0;
      }
    }

    .developers-content {
      width: 60%;
      text-align: left;
      padding-right: 4rem;

      @include respond($desktop_minimum) {
        width: 100%;
        padding-right: 0;
      }
      .tag {
        background: linear-gradient(to right, #edf3ff, #d3e3fa);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 700;
        width: 148px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #224391;
      }
    }

    .image-container {
      width: 40%;
      @include respond($desktop_minimum) {
        width: 100%;
        margin-top: 50px;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__great-for {
    background-color: #f6f9ff;

    padding: 120px 6.675% 65px 6.675%;
    @include respond($desktop_minimum) {
      padding: 50px 6.675% 50px 6.675%;
    }
    p {
      padding-right: 17%;
      @include respond($desktop_minimum) {
        padding-right: 0;
      }
    }

    .card-div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 46px;
      @include respond($tablet_minimum) {
        flex-direction: column;
        align-items: flex-start;
      }

      .card-content:nth-child(2) {
        margin: 0 24px;
        @include respond($tablet_minimum) {
          margin: 0 0 30px 0;
        }
      }
      .card-content {
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 8px;
        margin: 10px 0;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        width: 32%;
        height: 250px;
        padding: 40px;
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
        @include respond($desktop_minimum) {
          padding: 20px;
        }
        @include respond($tablet_minimum) {
          width: 100%;
          margin-bottom: 30px;
        }
        img {
          width: 56px;
          height: 36px;
          object-fit: contain;
        }

        h5 {
          font-weight: 600;
          font-size: 2.5rem;
          line-height: 35.2px;

          @include respond($desktop_minimum) {
            font-size: 1.9rem;
            line-height: 28px;
          }
        }
      }
    }
  }
  &__custom-branding {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 120px 6.675% 65px 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column-reverse;
      padding: 50px 6.675% 0 6.675%;
    }

    .custom-content {
      width: 60%;
      text-align: left;
      padding-left: 4rem;

      @include respond($desktop_minimum) {
        width: 100%;
        padding-left: 0;
      }
      .tag {
        background: linear-gradient(to right, #edf3ff, #d3e3fa);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 700;
        width: 148px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #224391;
      }
    }

    .image-container {
      width: 40%;
      margin-left: -4%;
      @include respond($desktop_minimum) {
        width: 100%;
        margin-top: 50px;
        margin-left: -8%;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__refer-business {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6.675% 65px 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 50px 6.675% 0 6.675%;
    }

    .refer-content {
      width: 60%;
      padding-right: 8rem;
      text-align: left;
      @include respond($desktop_minimum) {
        width: 100%;
        padding-right: 0;
      }
      .tag {
        background: linear-gradient(to right, #edf3ff, #d3e3fa);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 700;
        width: 148px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #224391;
      }
    }

    .image-container {
      width: 40%;
      margin-left: -6.675%;
      @include respond($desktop_minimum) {
        width: 100%;
        margin-top: 50px;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__slack-support {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 6.675% 85px 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column-reverse;
      padding: 50px 6.675% 50px 6.675%;
    }

    .support-content {
      width: 60%;
      padding-left: 4rem;
      text-align: left;
      @include respond($desktop_minimum) {
        width: 100%;
        padding-left: 0;
      }
      .tag {
        background: linear-gradient(to right, #edf3ff, #d3e3fa);
        border-radius: 10px;
        font-size: 14px;
        font-weight: 700;
        width: 148px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #224391;
      }
    }

    .image-container {
      width: 40%;
      height: 500px;
      margin-left: -3%;
      @include respond($desktop_minimum) {
        width: 100%;
        height: auto;
        margin-top: 40px;
        margin-left: 0;
      }
      img {
        width: 100%;
        height: inherit;
        object-fit: contain;
      }
    }
  }
  &__ready {
    background-color: #f6f9ff;
    .ready-container {
      padding: 120px 6.675% 0 6.675%;
      @include respond($desktop_minimum) {
        padding: 50px 6.675% 0 6.675%;
      }

      p {
        font-size: 20px;
        line-height: 32px;
        font-weight: 500;
        color: #0f1414;
        margin-right: 17%;
        margin-top: 16px;
        @include respond($desktop_minimum) {
          margin-right: 0;
        }
      }

      .ready-content {
        width: 40%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include respond($desktop_minimum) {
          width: 100%;
        }
        p {
          margin-right: 0;
          margin-bottom: 30px;
          text-align: center;
          color: #22428f;
          @include respond($tablet_minimum) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
