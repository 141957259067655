.shipment-first-step {
  @include dashboardWrapper;
  margin-top: 3rem;
  padding-bottom: 3rem;

  & > p {
    font-size: 1.2rem;

    @include respond(tablet) {
      font-size: 1.7rem;
    }
  }

}

.save-address {
  margin-top: 2rem;
  display: flex;
  align-items: center;

  & label,
  input {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  & label {
    margin-left: 1rem;
    font-size: 1.2rem;
    color: #3d5276;
    font-weight: 500;
  }
}

.insert-form {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  cursor: pointer;

  & span {
    color: var(--color-blue-dark);
    font-weight: 600;
    margin-left: 1rem;
    font-size: 1.5rem;
    padding: 0;
    align-self: center;
  }
}

.delete-item {
  display: flex;
  align-items: center;
  float: right;
  margin-top: 1rem;
  cursor: pointer;

  & span {
    font-size: 1.2rem;
    margin-left: 1rem;
    font-weight: 600;
    color: #ef476f;
  }
}

.item-wrapper {
  // animation: slideInLeft2 0.5s ease-in;
}
