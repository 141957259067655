:root {
  //colors
  --color-blue-dark: #003896;
  --color-blue-darker: #0F254A;
  --color-blue-dark3: #002461;
  --color-blue-dark4: #3d5276;
  --color-blue-light: #74b6f7;
  --color-blue-white: #194CA0;
  --color-red: #ef476f;

  --color-shipment: #f2f8fe;
  --color-tracking: #fdf9e9;
  --color-quote: #eef9f9;
  --color-kontainer: #fbf0f3;

  --color-gray: #505558;
  --color-gray2: #586274;
  --color-gray-light: #949dae;
  --color-intro: #35393d;
  --color-intro2: #83838e;
  --color-yellow: #ffc50f;
  --color-card: #c4c4c4;
  --color-form: #f8f8f9;
  --color-footer: #001b47;
  --color-wallet-card: #fafbfd;
  --color-green: #06a77d;
  --color-border: #eeeff1;
  --color-sub-heading: #3d5276;
  --color-address: #5e6e87;
  --color-black: #000000;
  --color-white: #ffffff;

  // dimensions
  --border-radius: 1.2rem;
}
