$tablet_minimum_index: 711px;
$tablet_minimum: 712px;
$tablet_maximum: 768px;
$desktop_minimum: 1024px;
$desktop_average: 1350px;
.App {
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
.about {
  &__intro {
    padding: 180px 6.675% 0 6.675%;
    @include respond($tablet_maximum) {
      padding: 120px 4% 0 4%;
    }
    // padding: 110px 96px 0px 96px;
  }
  &__new-partners {
    padding: 50px 0 50px 6.675%;
    background-color: #fffdf6;

    p {
      font-weight: 700;
      font-size: 40px;
      line-height: 88px;
      letter-spacing: -0.02em;
      color: #22428f;
      text-align: center;
      margin-bottom: 20px;
      white-space: nowrap;
    }

    @include respond(500px) {
      padding: 20px 0 20px 6.675%;

      p {
        font-size: 28px;
        line-height: 48px;
      }
    }

    .partner-logos {
      width: 100%;
      display: flex;
      gap: 6%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: auto;
      scroll-behavior: smooth;
    }

    .partner-logos::-webkit-scrollbar {
      display: none;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
    @include respond($tablet_maximum) {
      padding: 50px 4%;
    }
  }
  &__intro__text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    text-align: left;
    color: #22428f;
    @include respond($tablet_maximum) {
      font-size: 12px;
    }
  }
  &__hero {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 20%;
    @include respond($desktop_average) {
      gap: 8%;
    }
    @include respond($tablet_maximum) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    // gap: 129px;
  }
  &__hero__header {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 130%;
    color: #0f1414;
    text-align: left;
    flex: 1.25;
    @include respond($tablet_maximum) {
      font-size: 30px;
    }
  }
  &__hero__text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: left;
    color: #545859;
    flex: 1;
    @include respond($tablet_maximum) {
      font-size: 16px;
    }
  }
  &__images {
    padding-top: 250px;
    overflow-x: hidden;
    @include respond($tablet_maximum) {
      padding: 125px 4% 0 4%;
    }
  }
  &__images__wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    @include respond($tablet_maximum) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 16px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    img {
      box-shadow: 0px 50.5116px 80.8186px -40.4093px rgba(20, 33, 103, 0.1);
      border-radius: 8px;
    }
    &:first-child {
      transform: translate(-33%, 0);
      @include respond($tablet_maximum) {
        transform: none;
      }
    }
    &:nth-child(2) {
      transform: translate(-16.5%, -94px);
      @include respond($tablet_maximum) {
        transform: none;
      }
    }
    &:nth-child(3) {
      transform: translate(15%, -45px);
      @include respond($tablet_maximum) {
        transform: none;
      }
    }
    &:nth-child(4) {
      transform: translate(30%, -136px);
      @include respond($tablet_maximum) {
        transform: none;
      }
    }
  }
  &__counts {
    background: white;
    padding: 128px 15.3%;
    // padding: 128px 220px;
    @include respond(mini-mobile) {
      padding: 64px 24px;
    }
  }
  &__counts__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9%;
    @include respond($tablet_minimum) {
      gap: 24px;
    }
    // gap: 145px;
  }
  &__count {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__count__liner {
    display: block;
    width: 83px;
    border: 1px solid #e2e4e8;
    transform: rotate(90deg);
  }
  &__count__value {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: 0.2px;
    color: #22428f;
    @include respond(mini-mobile) {
      font-size: 24px;
      text-align: center;
    }
  }
  &__count__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #545859;
    @include respond($desktop_average) {
      font-size: 16px;
      line-height: 22px;
    }
    @include respond($tablet_maximum) {
      display: flex;
      text-align: left;
    }
  }
  &__features {
    background: #f6f9ff;
    padding: 128px 6.675%;
    @include respond(mini-mobile) {
      padding: 64px 6.675%;
    }
    // padding: 128px 96px;
  }
  &__features__header {
    text-align: left;
    @include respond($tablet_maximum) {
      text-align: center;
    }
  }
  &__features__container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 64px;
  }
  &__features__question * {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.16em;
    color: #22428f;
  }
  &__features__answer * {
    width: 70%;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 130%;
    color: #22428f;
    text-align: left;
    @include respond($tablet_maximum) {
      width: 100%;
      font-size: 36px;
      text-align: center;
    }
  }
  &__features__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2%;
    row-gap: 40px;
    @media screen and (min-width: $tablet_minimum) and (max-width: 1350px) {
      grid-template-columns: 1fr 1fr;
    }
    @include respond($tablet_minimum_index) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    // column-gap: 24px;
  }
  &__feature {
    background: #ffffff;
    box-shadow: 0px 15px 24px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    height: 324px;
    @media (max-width: 1350px) {
      height: unset;
    }
  }
  &__feature__wrap {
    padding: 10.125%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    // padding: 40px 40px 0 40px;
  }
  &__feature__icon * {
    margin-bottom: 24px;
  }
  &__feature__header * {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #0f1414;
    margin-bottom: 16px;
  }
  &__feature__content * {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #545859;
  }
  &__howItWorks {
    padding: 144px 6.675% 160px 6.675%;
    background: #1f3d87;
    color: white;
    @include respond(mini-mobile) {
      padding: 72px 6.675% 80px 6.675%;
    }
    // padding: 144px 96px 160px 96px;
  }
  &__howItWorks__container {
    display: flex;
    align-items: baseline;
    gap: 5%;
    @include respond($desktop_average) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
    // gap: 96px;
  }
  &__howItWorks__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    width: 80%;
    @include respond($desktop_average) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
  &__howItWorks__header * {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    @include respond($desktop_average) {
      text-align: center;
    }
  }
  &__howItWorks__content * {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    text-align: left;
    @include respond($desktop_average) {
      text-align: center;
      white-space: normal !important;
    }
  }
  &__howItWorks__items__wrap {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    column-gap: 4%;
    // column-gap: 48px;
    @include respond($tablet_minimum_index) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
  &__howItWorks__item__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    @include respond($tablet_minimum_index) {
      align-items: flex-start;
      text-align: left;
    }
  }
  &__howItWorks__item__icon * {
    margin-bottom: 24px;
  }
  &__howItWorks__item__header * {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
  &__howItWorks__item__content * {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.7);
  }
  &__services {
    background: #f6f9ff;
    padding: 120px 6.675% 100px 6.675%;
    // padding: 120px 96px 65px 96px;
    @include respond(mini-mobile) {
      padding: 60px 6.675% 60px 6.675%;
    }
  }
  &__services__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 64px;
  }
  &__services__header * {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 130%;
    color: #22428f;
    @include respond($tablet_maximum) {
      text-align: center;
    }
  }
  &__services__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2%;
    @include respond($tablet_maximum) {
      grid-template-columns: unset;
      grid-template-rows: repeat(3, 1fr);
      gap: 16px;
    }
    // gap: 24px;
  }
  &__service {
    height: 282px;
    cursor: pointer;
    @include respond(1270px) {
      height: 330px;
    }
    @include respond(1000px) {
      height: unset;
    }
  }
  &__team {
    padding: 120px 6.675%;
    // padding: 120px 96px;
    @include respond(mini-mobile) {
      padding: 60px 6.675%;
    }
  }
  &__team__wrap {
    display: flex;
    flex-direction: column;
    gap: 79px;
  }
  &__team__text {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 16px;
  }
  &__team__header * {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #22428f;
  }
  &__team__content * {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
    text-align: center;
    text-transform: capitalize;
    color: #22428f;
  }
  &__team__members__wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 63px;
    column-gap: 2%;
    place-items: center;
    // column-gap: 24px;
    @include respond($tablet_minimum_index) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
    @media (min-width: $tablet_minimum) and (max-width: $desktop_average) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
  }
  &__team__member__wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__team__member__image * {
    border-radius: 8px;
  }
  &__team__member__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    gap: 8px;
    @include respond($desktop_average) {
      align-items: center;
      justify-content: center;
    }
  }
  &__team__member__name * {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #0f1414;
  }
  &__team__member__role * {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-align: left;
    color: #545859;
  }
  &__contact {
    padding: 128px 6.675% 0 6.675%;
    background: #f6f9ff;
    // padding: 128px 96px;
    @include respond(tablet) {
      padding: 8.9% 2%;
    }
  }
  &__contact__wrap {
    display: flex;
    flex-direction: column;
  }
  &__contact__header {
    margin-bottom: 64px;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 130%;
      color: #22428f;
      text-align: left;
      @include respond($tablet_maximum) {
        text-align: center;
      }
    }

    .sub-content {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      letter-spacing: 0.2px;
      color: #0f1414;

      @include respond(749px) {
        text-align: center;
        font-size: 16px;
      }
    }
  }
  &__contact__items {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
    border-radius: 8px 8px 16px 16px;
  }
  &__contact__items__wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    // padding: 0 142px 0 40px;
    @include respond($tablet_minimum_index) {
      padding: 0 3.225% 10% 3.225%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    @media (min-width: $tablet_minimum) and (max-width: $desktop_average) {
      display: grid;
      padding-right: 3.225%;
      place-items: center;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }
  &__contact__item {
    height: 700px;
    width: 100%;
    padding: 0 11.4% 4.6% 3.225%;
    @include respond($desktop_average) {
      &:nth-child(2) {
        border-right: none !important;
      }
      border-bottom: 1px solid #e5eaf4;
    }
    @include respond($tablet_minimum_index) {
      height: unset;
      border-bottom: none;
    }
  }
  &__contact__item:first-child {
    padding-right: 3.075% !important;
  }
  &__contact__item:not(:last-child) {
    border-right: 1px solid #e5eaf4;
    padding-right: 0;
    @include respond($tablet_minimum_index) {
      border-right: none;
      border-bottom: 1px solid #e5eaf4;
    }
  }
  &__contact__item__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    @include respond($tablet_minimum_index) {
      align-items: center;
      justify-content: center;
    }
  }
  &__contact__item__icon {
    margin-top: 41px;
  }
  &__contact__item__header * {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #0f1414;
    margin: 41px 0 0 0;
  }
  &__contact__item__content {
    .addresses {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @include respond($tablet_minimum_index) {
        align-items: center;
        justify-content: center;
      }
    }

    .drops {
      width: 25%;
      border-bottom: 1px solid #e5eaf4;
      padding: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include respond(1100px) {
        width: 50%;
        .hide-space {
          display: none;
        }
      }

      @include respond(500px) {
        width: 100%;

        .hide-space {
          display: none;
        }
      }
    }

    .hide-space {
      border-right: 1px solid #e5eaf4;
    }
    @include respond(1100px) {
      .hide-space {
        display: none;
      }
    }
    .address_item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      text-align: left;
      @include respond($tablet_minimum_index) {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
    .address_header * {
      text-align: left;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #0f1414;
    }
    .address_content * {
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: #545859;
      @include respond($tablet_minimum_index) {
        text-align: center;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #0f1414;
    }
    .social-items {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
}
