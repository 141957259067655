$tablet_maximum: 1064px;
$tablet_minimum: 712px;
$mobile_maximum: 700px;
.overlay.enabled {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(45deg, white, transparent);
}
.new-home-page {
  &__container {
    width: 100%;
    min-width: 80%;
    margin: 0 6.53%;
    display: flex;
    @include respond(1200px) {
      margin: 0 3%;
    }

    @include respond($tablet_maximum) {
      width: unset !important;
      margin-right: 0;
      margin-left: 6.53%;
    }
    &.header__navigation {
      li:not(.logo) {
        @include respond($tablet_maximum) {
          display: none;
        }
      }
    }
  }
  &__footer-container {
    width: 100%;
    min-width: 80%;
    padding: 0 6.53%;
    display: flex;
  }

  &__mobile__menu__hamburger {
    display: none;
    flex-direction: column;
    gap: 5px;
    transition: all 0.4s ease;
    span {
      transition: all 0.4s ease;
      border: 1px solid #0f1414;
      width: 25px;
      display: block;
      transform-origin: 8px 1px;
    }
    @include respond($tablet_maximum) {
      display: flex;
    }
    &.open {
      span {
        transition: all 0.4s ease;
        border-color: #22428f;
      }
      span:first-child {
        transform: rotate(45deg);
      }
      span:nth-child(2) {
        display: none;
      }
      span:last-child {
        transform: rotate(-45deg);
      }
    }
  }
  &__mobile__menu::-webkit-scrollbar {
    display: none;
  }
  &__mobile__menu {
    display: none;
    width: 90%;
    position: absolute;
    min-height: auto;
    max-height: 85vh;
    // max-height: 100vh;
    overflow-y: scroll;
    left: 50%;
    transform: translate(-50%, 13vh);
    background: rgba(246, 247, 251, 0.8);
    backdrop-filter: blur(8px);
    border: 1px solid #22428f;
    z-index: 2;
    border-radius: 1em;

    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      list-style: none;
      gap: 22px;
      padding: 20px;
    }
    &.open {
      display: block;
      .cta-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .mobile-service {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        white-space: nowrap;

        img {
          width: 12px;
          margin-left: 4px;
        }
      }
      li {
        list-style-type: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;

        a {
          text-decoration: none;
          color: #0f1414;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__navigation {
    width: 100%;
    min-width: 100%;
    background: #f6f7fbdd;
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e4e8;
    position: fixed;
    top: 0;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    z-index: 2;
    &.use-china {
      background: #fffdf6 !important;
    }

    .logo {
      margin-right: 70px;

      @include respond(1171px) {
        margin-right: 30px;
      }
    }

    .gateway-logo {
      object-fit: contain;
      height: 50px;

      @include respond(500px) {
        height: 40px;
        margin-left: -20px;
      }
    }
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0;
    }

    li {
      list-style-type: none;
      margin-right: 2rem;
      font-size: 15px;
      font-weight: 500;
      line-height: 21.6px;

      @include respond(1299px) {
        margin-right: 1.8rem;
        font-size: 13px;
      }

      a {
        text-decoration: none;
        color: #0f1414;
      }
    }

    ul li:last-child {
      margin-left: auto;
      margin-right: 0;

      .cta-item:last-child {
        margin-right: 0;
      }
    }

    .bttn-sm {
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 0 !important;
    }

    .cta-items {
      display: flex;
      width: 100%;
      justify-content: center;
      text-align: center;
    }

    .cta-item {
      margin: 0 10px;

      a {
        text-decoration: none;
      }
    }
  }

  &__services {
    position: relative;
    cursor: pointer;

    &:hover {
      .service__dropdown {
        display: block;
      }
    }
    a {
      cursor: pointer;

      @include respond($tablet_maximum) {
        text-align: right;
      }
    }

    .service__dropdown {
      display: none;
      position: absolute;
      z-index: 50;
      top: 6;

      @include respond($tablet_maximum) {
        position: relative;
        display: block;
      }

      .dropdown__content {
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 6px;
        padding: 25px 30px;
        margin-top: 30px;
        width: 100%;
        display: flex;
        list-style: none;
        text-align: left;

        @include respond($tablet_maximum) {
          display: block;
          text-align: right;
          margin-top: 0;
          background-color: transparent;
          padding: 0;
          box-shadow: none;
        }
      }
      .column__items:last-child {
        margin-right: 0;

        @include respond($tablet_maximum) {
          margin-bottom: 0;
        }
      }
      .column__items {
        display: block;
        margin-right: 50px;

        @include respond($tablet_maximum) {
          margin-right: 0;
          margin-top: 24px;
        }
      }
      .product__items {
        display: block;
        margin-right: 50px;
        @include respond($tablet_maximum) {
          margin-right: 0;
          margin-top: 15px;
        }

        li:first-child {
          margin-top: 0 !important;
        }
        li:last-child {
          margin-bottom: 0 !important;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;

        @include respond($tablet_maximum) {
          text-align: right;
          align-items: flex-end;
        }
        li:last-child {
          margin-left: 0 !important;
        }
        li {
          margin-bottom: 10px;
          margin-top: 10px;
          margin-left: 0;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }

          @include respond($tablet_maximum) {
            margin-bottom: 0;
            opacity: 0.9;
          }

          @include respond($tablet_maximum) {
            font-size: 15px;
            line-height: 22px;
          }

          a {
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
      }

      .column-item-header {
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
  }
}
