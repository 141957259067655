.quote-summary {
  @include dashboardWrapper;
  margin-top: 3rem;
  padding-bottom: 3rem;

  &__card {
    padding: 3rem 2rem;
    background-color: #fafbfd;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    border-radius: 0.7rem;

    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__route {
        --color-text: #3d5276;
        color: var(--color-text);
        font-size: 1.5rem;
        @include respond(tablet) {
          font-size: 1.5rem;
        }

        &:nth-child(2) {
          margin: 0 0.7rem;
          display: inline-block;
        }
      }
      &__cost {
        color: var(--color-blue-dark);
        font-weight: 700;
        margin: 1rem 0;
      }

      &__weight {
        font-size: 1.7rem;
        font-weight: 500;
        color: var(--color-text);
        margin-top: 1.5rem;
      }
    }

    &__bottom {
      margin-top: 3rem;
      & p {
        font-weight: 500;
        text-align: center;
        width: 40rem;
        margin: auto;
        color: hsl(346, 84%, 61%);
        font-size: 1.5rem;

        @include respond(tablet) {
          font-size: 1.8rem;
          width: 90% !important;
        }
      }
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-blue-dark);
    margin: 4rem 0;

    @include respond(tablet) {
      font-size: 1.7rem;
    }
  }

  &__rates {
    border: 1px solid var(--color-border);
    margin-bottom: 1rem;
    border-radius: 0.7rem;
    padding: 1.5rem;
    transition: background-color 0.3s;
    cursor: pointer;

    @include respond(tablet) {
      font-size: 10px;
    }

    &--shipment-types {
      & p:nth-child(1) {
        color: var(--color-blue-dark);
      }
    }

    &--selected {
      border: 2px solid var(--color-blue-dark);
    }

    &:hover {
      background-color: rgb(247, 246, 246);
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & span {
        color: var(--color-gray2);
        font-weight: 700;
        font-size: 1.5rem;

        &.selected {
          color: #003896;
        }

        @include respond(tablet) {
          font-size: 1.5em;
        }
      }
      &--summary {
        & h4 {
          max-width: 75%;
        }
        & span {
          color: var(--color-text);
        }
      }
      &--option3 {
        justify-content: flex-start;
        align-items: center;
        & figure {
          display: block;
          margin: 0;
          padding: 0;
        }

        & span {
          margin-left: 1.5rem;
        }
      }
    }

    &__bottom {
      p:first-child {
        color: var(--color-gray2);
        font-weight: 700;
        font-size: 1.5rem;
        max-width: 85%;

        &.selected {
          color: #003896 !important;
        }

        @include respond(tablet) {
          font-size: 1.5em;
        }
      }

      & p:not(:first-child) {
        color: #304466;
        font-size: 1.3rem;

        @include respond(tablet) {
          font-size: 1.5em;
        }
      }

      & p.selected {
        color: #003896 !important;
      }

      &--shipment-types {
        & p:not(:first-child) {
          width: 80%;
          line-height: 2.5rem;

          & p.selected {
            color: #003896 !important;
          }
        }
      }

      &__edit {
        display: flex;
        align-items: center;
        color: var(--color-blue-dark);
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 2rem;

        & span {
          margin-left: 1rem;
        }
      }
    }
  }

  &__apply-bonus {
    color: var(--color-blue-dark);
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1.5rem;
    display: block;
    cursor: pointer;

    & small {
      font-size: 1.1rem;
      color: rgb(5, 58, 5);
      font-weight: 500;
      margin-left: 0.5rem;
    }
  }

  &__terms__checkbox {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    margin-top: 3rem;

    @include respond(mobile) {
      font-size: 1.25rem;
    }

    & input {
      transform: scale(1.4);
    }

    & label {
      margin: 0;
      margin-left: 1rem;
    }
  }

  &__terms {
    color: var(--color-gray);
    line-height: 2rem;
    font-weight: 500;
    margin-top: 2.5rem;
    text-align: left;
    font-size: 1.3rem !important;

    @include respond(mobile) {
      font-size: 1.25rem;
    }
  }
}
