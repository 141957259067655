@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&display=swap');

@import './styles/abstracts/functions';
@import './styles/abstracts/mixins';
@import './styles/abstracts/variables';

@import './styles/base/animation';
@import './styles/base/base';
@import './styles/base/typography';
@import './styles/base/utilities';

@import './styles/components/busy-overlay';
@import './styles/components/button-primary';
@import './styles/components/custom-spinner';
@import './styles/components/custom-dropdown';
@import './styles/components/header';
@import './styles/components/input-group';
@import './styles/components/map';
@import './styles/components/nav';
@import './styles/components/submit-button';
@import './styles/components/get-quote';
@import './styles/components/location-details';
@import './styles/components/mailchimp';
@import './styles/components/blog-item';
@import './styles/components/blog-detail';
@import './styles/components/custom-loader';
@import './styles/components/get-quotes-form';
@import './styles/components/contact-form';
@import './styles/components/navigation';
@import './styles/components/campaign-modal';

@import './styles/layout/about-us';
@import './styles/layout/about';
@import './styles/layout/careers';
@import './styles/layout/faq';
@import './styles/layout/footer';
@import './styles/layout/partnership';
@import './styles/layout/press';
@import './styles/layout/services';
@import './styles/layout/subscription';
@import './styles/layout/payment-successful';
@import './styles/layout/quote-summary';
@import './styles/layout/requests-first-step';
@import './styles/layout/requests';

@import './styles/pages/home';
@import './styles/pages/sea-freight';
@import './styles/pages/new-home-page';
@import './styles/pages/api-documentation';
@import './styles/pages/plugin';
@import './styles/pages/lsp';
@import './styles/pages/gateway';
@import './styles/pages/delete-account';
@import './styles/pages/shopnship';
@import './styles/pages/services';
@import './styles/pages/shipping-checklist';
@import './styles/pages/faq';
@import './styles/pages/blog';
@import './styles/pages/resources';
@import './styles/pages/exports';
@import './styles/pages/china-imports';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'General Sans', sans-serif;
  // font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ytp-cued-thumbnail-overlay > .ytp-cued-thumbnail-overlay-image {
  background-size: 100% 100% !important;
}

.slick-slide > div {
  margin: 5px 10px;
}

.volumetric-weight {
  width: 100%;
  margin-top: 30px;
}

.switch-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: 16px;
    font-weight: 500;
    color: #545859;
    margin-bottom: 0;

    @include respond(mini-mobile) {
      font-size: 14px;
    }
  }
}

.switch {
  margin-left: 30px;
  width: 42px;
  height: 20px;
  background-color: #979797;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s;
}

.switch.active {
  background-color: #0a16a7;
}

.circle {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch.active .circle {
  transform: translateX(17px);
}

.input-switch {
  width: 40px;
  height: 20px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}

.input-switch.active {
  background-color: #0a16a7;
}

.switch-slider {
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  transition: transform 0.3s;
}

.calculator-img-container {
  position: relative;
  height: 150px;
  margin-top: -30px;
  @include respond(1200px) {
    margin-top: 0;
  }
}

.contact-support {
  border: #e2e4e8 1px solid;
  background-color: #fff;
  color: #22428f;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  padding: 18px 30px;
  border-radius: 12px;
  width: fit-content;
  white-space: nowrap;

  @include respond(900px) {
    font-size: 14px;
    padding: 18px 20px;
  }

  &:hover {
    color: #22428f;
  }
}

.start-shipping {
  border: #22428f 1px solid;
  background-color: #22428f;
  color: #fff;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  padding: 18px 30px;
  border-radius: 12px;
  width: fit-content;
  white-space: nowrap;
  text-decoration: none;

  @include respond(900px) {
    font-size: 14px;
    padding: 18px 20px;
  }

  &:hover {
    color: #fff;
  }
}

.scroller {
  max-width: 1200px;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 6rem;
}

.scroller[data-animated='true'] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 0.5) 80%,
    transparent
  );
  mask: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5) 20%,
    rgba(255, 255, 255, 0.5) 80%,
    transparent
  );
}

.scroller[data-animated='true'] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.required {
  color: red;
}
