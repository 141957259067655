.button-primary {
  font-size: 1.7rem;
  background-color: var(--color-blue-dark);
  padding: 1.3rem;
  color: white;
  border-radius: 0.7rem;
  border: none;
  margin-top: 2rem;
  transition: all 0.3s;

  &--center {
    width: fit-content;
    margin: auto;
    display: block;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 3px 10px rgb(0, 0, 0, 0.5);
  }
}
