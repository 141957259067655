.blog-item {
  display: flex;
  margin: 6rem 0;
  cursor: pointer;
  padding: 4rem 6rem;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;

  &__metadata {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 10px;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 30px;
    }

    p {
      font-size: 1rem;
      margin-right: 20px;
    }

    img {
      width: 15px;
      margin-right: 5px;
    }
  }

  &__text {
    width: 70%;
    text-align: left;
    margin-right: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-size: 3rem;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.5rem;
      line-height: 1.8;
    }
  }

  &__image {
    width: 400px;

    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  @include respond(medium) {
    align-items: center;
    padding: 2rem;

    &__text {
      margin-right: 2rem;
      text-align: left;

      h2 {
        font-size: 2.3rem;
        margin-bottom: 5px;
      }

      p {
        font-size: 1.3rem;
      }
    }
  }

  @include respond(mobile) {
    padding: 0;
    flex-direction: column-reverse;

    &__text {
      width: 100%;
      padding: 0 2rem 2rem 2rem;
      margin: 0;

      h2 {
        font-size: 2;
        margin-bottom: 5px;
      }

      p {
        font-size: 1.65rem;
      }
    }

    &__metadata {
      p {
        font-size: 1.2rem;
      }
    }

    &__image {
      width: 95%;
      padding: 1rem 0;

      img {
        width: 100%;
      }
    }
  }
}

.no-link-styles {
  text-decoration: none;
  color: inherit;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
