.header {
  padding: 3rem 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);

  @include respond(tablet) {
    padding: 2rem;
  }
  &__logo {
    cursor: pointer;
  }

  &--white {
    background-color: #fff;
  }
  &__navigation {
    // margin-left: 15%;
    display: flex;
    list-style: none;

    @include respond(smaller) {
      display: none;
    }

    &__mobile {
      display: flex;
      align-self: center;

      &__lines {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        cursor: pointer;

        & i {
          position: relative;
          background-color: #fff;
          border-radius: 100rem;
          display: block;
          line-height: 0;
          transition: all 0.3s;
          position: relative;
          width: 2.3rem;
          height: 3px;
          z-index: 100000;

          &::before,
          &::after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            height: 3px;
            background-color: #fff;
            border-radius: 100rem;
            width: 5rem;
            z-index: 100000 !important;
            transition: all 0.5s;
          }

          &::before {
            width: 3rem;
            transform: translateY(-1rem);
          }
          &::after {
            transform: translateY(1rem);
            width: 2.8rem;
          }
        }

        &--open {
          & i {
            &::before {
              width: 3rem;
              transform: rotate(135deg);
              background-color: black !important;
            }
            &::after {
              width: 3rem;
              transform: rotate(-135deg);
              background-color: black !important;
            }
          }
        }

        &--white {
          & i {
            background-color: black;

            &::before,
            &::after {
              background-color: black;
            }
          }

          &--open {
            & i {
              background-color: #fff;
            }
          }
        }
      }

      &__list {
        position: fixed;
        top: 0;
        right: -50rem;
        width: 0;
        height: 100vh;
        background-color: #fff;
        z-index: 10000;
        display: flex;
        flex-direction: column;
        transition: all 0.3s;
        border-radius: 50%;
        transform: scale(0.9);
        padding: 4rem 2rem 1rem 2rem;
        list-style: none;

        & li {
          cursor: pointer;

          &:nth-child(3) {
            display: flex;
            justify-content: space-between;
            padding: 1.7rem 0 !important;
            font-size: 1.5rem;
            color: black;
          }
          & a {
            display: block;
            padding: 1.7rem 0 !important;
            font-size: 1.5rem;
            text-decoration: none;
            color: black;
          }

          &.active {
            &,
            & a {
              color: var(--color-yellow);
            }
          }

          &:last-child {
            align-self: stretch;

            // & button {
            //   width: 100%;
            //   font-size: 1.5rem;
            //   padding: 1.5rem;
            //   border: none;
            //   border-radius: 0.5rem;
            //   background-color: var(--color-blue-dark);
            //   color: white;
            //   font-weight: 600;

            //   &:focus {
            //     outline: none;
            //   }
            // }
          }
        }

        &--open {
          right: 0;
          transform: scale(1);
          width: 100vw;
          border-radius: 0;
          display: flex !important;
          flex-direction: column;
          justify-content: space-between !important;
          animation: slideInUp 0.2s;
        }

        &--close {
          animation: slideOutDown 0.2s;
          // display: none !important;
        }

        &--default {
          display: none !important;
        }

        &--dropdown {
          right: 0;
          left: -50rem;
          z-index: 110000;
          border-radius: 0;
          transform: scale(1);
          transition: all 0.3s;

          & h4 {
            color: gray;
            & > div {
              transform: rotate(180deg);
            }

            &:first-child {
              cursor: pointer;
            }
          }
          & a {
            padding: 0;
          }
          &--open {
            left: 0;
            width: 100vw;
            border-radius: 0;
            transform: scale(1);
          }
        }
      }
    }

    &__link {
      font-size: 1.4rem;
      &:not(:last-child) {
        margin-right: 2rem;
      }

      & a {
        text-decoration: none;
        color: white;
        transition: all 0.3s;

        &:hover {
          color: var(--color-yellow);
        }

        & img {
          animation: all 0.3s;
          &.close {
            transform: rotate(180deg);
          }
        }
      }
      &--white a {
        color: black;
      }
      &--active a {
        color: var(--color-yellow);
      }

      &--dropdown {
        position: relative;
      }
    }

    &__dropdown {
      position: absolute;
      background-color: #fff;
      min-width: 50rem;
      top: 4rem;
      display: flex;
      z-index: 100;

      //   transform: translateY(20) scale(0);
      opacity: 0;

      @include respond(medium) {
        right: -50vw;
        transform: translateX(-50%);
      }

      &__left {
        &__list {
          list-style: none;
          padding-left: 0 !important;
        }
        &__item {
          display: flex;
          padding-left: 2rem !important;
          color: black;
          cursor: pointer;
          transition: all 0.2s;

          &:hover {
            background-color: rgba(72, 57, 107, 0.143);
          }
          & img {
            width: 7rem;
          }

          & > div {
            margin-top: 4rem;
            margin-left: 1rem;

            & > h3 {
              color: var(--color-blue-dark);
            }

            & p {
              font-size: 1.2rem;
              line-height: 1.9rem;
            }
          }
        }
      }

      &__right {
        padding: 4rem;
        background-color: #f2f1f1;
        align-self: stretch;

        &__list {
          list-style: none;
          padding: 0;

          & h3 {
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: 2rem;
          }
        }

        &__item {
          margin-bottom: 1rem;
          & a {
            color: black;
            font-size: 1.5rem;

            &:hover {
              text-decoration: underline;
            }
          }

          &--active a {
            color: var(--color-yellow);
          }
        }
      }

      &--open {
        opacity: 1;
        animation: slideInBottom 0.4s;
      }

      &--close {
        opacity: 0;
        animation: slideOutTop 0.4s;
      }
    }
  }

  &__authentication {
    // justify-self: flex-end;
    // margin-left: auto;
    display: flex;
    align-items: center;

    @include respond(smaller) {
      display: none;
    }

    & a {
      text-decoration: none;
      transition: all 0.3s;

      & button {
        font-size: 1.4rem;
        &:focus {
          outline: none;
        }
      }
      &:first-child button {
        background-color: transparent;
        color: white;
        border: none;
        display: flex;
        align-items: center;
        margin-right: 4rem;
        transition: all 0.3s;

        & svg {
          margin-right: 0.5rem;
          stroke: white;
          transition: all 0.3s;
        }
        &:hover {
          color: var(--color-yellow);

          & svg {
            margin-right: 0.5rem;
            stroke: var(--color-yellow);
          }
        }
      }

      &:last-child button {
        padding: 1rem 2rem;
        background-color: var(--color-yellow);
        border: none;
        border-radius: 0.5rem;
      }
      &.header__authentication__button--white button {
        color: black;
        & svg {
          margin-right: 0.5rem;
          stroke: #202223;
          transition: all 0.3s;
        }
      }
    }
  }
}
