.services {
  &__carousel {
    // margin-top: 3rem;
    &__item {
      display: flex;
      height: 70 vh;
      justify-content: center;
      align-items: center;
      position: relative;
      & > div:first-child {
        position: unset !important;

        @include respond(tablet) {
          height: 55vh;
        }
      }

      &__cover {
        position: absolute;
        @include respond(tablet) {
          font-size: 3rem;
        }
        background-color: none;
        transition: all 0.4s;

        &:hover {
          // background-color: rgba(0, 0, 0, 0.7);
        }

        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // height: 100%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: white;
        padding: 4rem;
        text-align: left;

        & h1 {
          font-size: calc(1.5rem + 1.5vw);
          font-weight: 600;
          max-width: 40rem;

          @include respond(tablet) {
            font-size: 3.3rem;
            max-width: 40rem;
          }
        }

        & p {
          font-size: 1.5rem;
          margin-top: 2rem;
          max-width: 40rem;
          line-height: 2rem;
          font-weight: 400;

          @include respond(tablet) {
            font-size: 1.8rem;
            line-height: 2.7rem;
          }
        }

        & button {
          font-size: 1.7rem;
          background-color: var(--color-blue-dark);
          padding: 1.3rem;
          @include respond(tablet) {
            font-size: 3rem;
          }
          color: white;
          border-radius: 0.7rem;
          border: none;
          margin-top: 2rem;
          transition: all 0.3s;

          &:focus {
            outline: none;
          }

          &:hover {
            box-shadow: 0 3px 10px rgb(0, 0, 0, 0.5);
          }

          @include respond(tablet) {
            font-size: 1.4rem;
            padding: 1.5rem;
          }
        }
      }
    }
  }

  &__values {
    background: rgba(196, 196, 196, 0.12);
    padding: 7rem 0;

    & button {
      display: block;
      width: fit-content;
      margin: auto;
      margin-top: 4rem;
      font-size: 1.7rem;
      background-color: var(--color-blue-dark);
      padding: 1.3rem 2.5rem;
      color: white;
      border-radius: 0.7rem;
      border: none;

      transition: all 0.3s;

      &:focus {
        outline: none;
      }

      &:hover {
        box-shadow: 0 3px 10px rgb(0, 0, 0, 0.5);
      }
    }

    @include respond(tablet) {
      padding: 4rem 0;
      margin-bottom: 4rem !important;

      & button {
        font-size: 1.4rem;
        padding: 1.7rem 7rem;
        border-radius: 0.5rem;
      }
    }
  }

  &__steps {
    padding: 4rem;
    margin-bottom: 3rem;
    & h2 {
      color: var(--color-blue-dark);
      font-weight: 600;
      font-size: 2.6rem;
      margin-bottom: 3.5rem;
    }

    @include respond(tablet) {
      padding: 2rem;

      & h2 {
        font-size: 2rem;
      }
    }
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      align-items: center;

      &__left {
        display: flex;
        flex-direction: column;
      }

      &__right {
        & > div {
          transform: scale(0.9);
        }

        @include respond(tablet) {
          margin-top: 4rem;
          & > div {
            transform: scale(1);
          }
        }
      }

      @include respond(tablet) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__step {
    border: 1px solid #74b6f7;
    padding: 2rem 3rem 5rem 2rem;
    border-radius: 0.7rem;
    width: 100%;

    font-weight: 600;
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    & h3 {
      color: var(--color-blue-dark);
      margin-bottom: 2rem;
      font-weight: 600;
      font-size: 2.1rem;
    }

    & p {
      font-size: 1.4rem;
      max-width: 75%;
      line-height: 2rem;
      font-weight: 400;
      color: #202223;

      @include respond(tablet) {
        max-width: 100%;
      }
    }
  }
}
.carousel-status {
  display: none !important;
}
.carousel .thumbs-wrapper {
  margin: 0 !important;
}
