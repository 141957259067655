.get-quote {
  width: 920px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .quote-items-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    border: 1px solid #003896;
    border-radius: 50px;
    background-color: none;
  }

  .quote-inputs {
    display: flex;
    flex-grow: 1;
  }

  .quotes-item {
    width: fit-content;
    height: 40px;
    padding: 1em;
    margin: 2em 0em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__form-controls {
    display: flex;
    padding: 10px;
    flex-grow: 1;
  }

  &__submit {
    width: 150px;
    max-height: 50px;
    background: #ffc50f;
    display: inline-block;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    border: none;
    border-radius: 40px;
    line-height: 2;
    cursor: pointer;
    color: #003896;
    margin: 10px;
  }

  p {
    margin: 0;
    margin-bottom: 0.25rem;
    color: red;
    font-size: 0.85rem;
  }
}

.form-control {
  flex-direction: column;
  color: #003896;
  font-weight: bold;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: none;
  padding: 0 4rem;

  label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-align: left;
    font-size: 1.3rem;
  }

  &:hover {
    background-color: #efefef;
  }

  input {
    background: none;
    border: none;
    line-height: 2;
    width: 160px;
    font-size: 1.2rem;
    cursor: pointer;
    color: #003896;
    font-weight: 400;
  }

  input::placeholder {
    outline: none;
    font-weight: 200;
    color: #111010;
    font-size: 1.1rem;
  }

  input:focus {
    outline: none;
  }

  input:active {
    outline: none;
  }
}

.form-control:nth-child(1),
.form-control:nth-child(2) {
  border-radius: 0px;
  border-right: 1.5px solid var(--color-blue-dark);

  @include respond(mobile) {
    border-right: none !important;
  }
}

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 830px;
@media (max-width: $breakpoint-tablet) {
  .get-quote {
    display: none;
  }
}
