.blog {
  &__container {
    width: 920px;
    margin: 0 auto;
  }

  &__header {
    padding: 8rem;
    text-align: left;
    background-color: lightsteelblue;

    h1 {
      color: #444;
      font-size: 4rem;
      font-weight: bold;
      margin: 2rem 0;
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 4rem;
    }

    #mc_embed_signup {
      width: 60%;
      margin: 0;
      padding: 0;
    }

    #mc_embed_signup .button {
      margin-top: 8px;
    }
  }

  &__loader {
    height: 80vh;
    display: flex;
    align-items: flex-start;
  }

  @include respond(medium) {
    #mc_embed_signup {
      margin: 0;
      padding: 0;
      width: 80%;
    }

    #mc_embed_signup .button {
      margin-top: 10px;
    }

    .form-controls {
      width: 100%;
    }

    &__container {
      width: 80%;
    }
  }

  @include respond(mobile) {
    #mc_embed_signup {
      width: 100%;
    }

    &__header {
      padding: 2rem;
    }

    &__container {
      width: 90%;
    }

    // .form-controls {
    //   width: 100%;
    // }
  }
}
