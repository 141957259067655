$standard_mobile: 400px;
$small: 56.25em;
$tablet_average: 600px;
$tablet_minimum: 712px;
$tablet_maximum: 800px;
.new-home-page {
  &__notice {
    margin-bottom: 1.5em;
    width: fit-content;
    border-radius: 50px;
    background: #e8eeff;
    border: 1px solid #c5d6ff;

    @include respond($tablet_minimum) {
      margin: 0 auto 1.5em auto;
    }

    &__wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px 16px;

      @include respond($tablet_minimum) {
        padding: 16px;
        align-items: flex-start;
      }
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }

    &__text {
      span {
        font-size: 14px;
        color: #22428f;
        font-weight: 500;
        line-height: 1.5em;
        font-family: 'General Sans';

        @include respond($tablet_minimum) {
          b:last-child {
            display: block;
            margin-top: 10px;
          }
        }

        @include respond(508px) {
          font-size: 10px;
        }
      }
    }

    &__action {
      margin-left: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #22428f;
      width: 30px;
      height: 30px;
      border-radius: 15px;
    }
  }

  .banner-container {
    width: 100%;
    cursor: pointer;
    margin-left: -4em;

    @include respond(1200px) {
      margin-left: -3em;
    }

    @include respond(1000px) {
      margin-left: -2em;
    }

    @include respond($tablet_minimum) {
      margin-left: 0;
    }

    .banner-item {
      height: auto;
      width: 100%;
      text-decoration: none;
      padding: 0 20px;

      img {
        height: inherit;
        width: inherit;
        object-fit: contain;
      }

      @include respond(medium) {
        padding: 0 10px;
      }
    }
    .multi-banner {
      @include respond(medium) {
        padding: 0 30px;
      }
    }
  }

  .slick-dots li {
    margin: 0 !important;
  }

  .banner-mobile {
    display: none;
  }

  .web-staff {
    display: block;

    @include respond($tablet_minimum) {
      display: none;
    }
  }
  @include respond($tablet_minimum) {
    width: 100%;
    .banner-web {
      display: none;
    }
    .banner-mobile {
      display: block;
    }
  }
  .homepage-container {
    width: 100%;
  }
  .small-image {
    width: 35.2px;
    min-width: 35.2px;
    height: 35.2px;
    min-height: 35.2px;
    margin-bottom: 40px;
  }

  .text {
    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }

  .bold-text {
    font-weight: 600;
  }

  .light-text {
    font-weight: 400;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }

  .bttn-yellow {
    background: #ffd708;
    color: #22428f;
  }
  .bttn-light-blue {
    background: #e8eeff !important;
    border: 1px solid #c5d6ff;
    color: #22428f !important;
  }

  .bttn-blue {
    background: #22428f;
    color: #ffffff !important;
  }

  .bttn-white {
    background: #ffffff;
    color: #22428f;
    box-shadow: 0px 0px 0px 2px #22428f inset;
  }

  .bttn-lg {
    min-width: 160px;
    height: 48px;
    padding: 12px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    border-radius: 8px;
    color: #22428f;
  }

  .bttn-sm {
    width: 120px;
    min-width: 120px;
    height: 40px;
    padding: 12px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 22.4px;
    border-radius: 8px;
  }

  h2 {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 3.2rem;
    color: #22428f;
    text-align: left;
    margin-bottom: 4rem;
  }
  @media screen and (min-width: 48rem) {
    h2 {
      font-size: 4.8rem;
      font-weight: 600;
      line-height: 58px;
      color: #22428f;
      text-align: left;
      margin-bottom: 4rem;
    }
  }

  .cta-items {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  .cta-item {
    margin: 0 5px;

    a {
      text-decoration: none;
    }
  }

  .disable-scrollbars {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      background: transparent;
      width: 0px;
    }
  }

  &__banner {
    margin: 10rem 0 5rem 0;
  }

  &__mobile-notif {
    padding: 70px 0 0;

    .mobile-alert {
      padding: 20px 0;
      background-color: #fff5d3;

      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: none;
      }

      p {
        font-size: 16px;
        color: #0f1414;
        font-weight: 600;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-right: 5px;
      }
    }
  }

  &__flexible-pricing {
    // background-color: #fffdf6;
    position: relative;
    padding: 100px 0 0 6.675%;
    @include respond($desktop_minimum) {
      padding: 100px 0 0 1%;
    }

    .content-wrap {
      background-color: #fffdf6;
      border: 1px solid #f9c420;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      width: 97%;
      padding: 3.675%;

      @include respond($desktop_minimum) {
        padding: 3.675%;
        width: 100%;
      }
    }

    .new-partners {
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 88px;
        letter-spacing: -0.02em;
        color: #0f1414;
        text-align: left;
        margin-top: 50px;
        margin-bottom: 20px;
        white-space: nowrap;
      }

      // .scroller {
      //   max-width: 1000px;
      //   border: 4px solid red;
      // }

      // .scroller__inner {
      //   width: 100%;
      //   display: flex;
      //   gap: 6%;
      //   padding-block: 20px;
      //   justify-content: space-between;
      //   align-items: center;
      //   flex-wrap: wrap;
      //   overflow-x: auto;
      //   scroll-behavior: smooth;
      // }

      // .scroller__inner::-webkit-scrollbar {
      //   display: none;
      // }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }

    .link-container {
      text-decoration: none;
      width: fit-content;
      border-radius: 50px;
      background-color: #f3f6f9;
      padding: 12px 15px;
      display: flex;
      align-items: center;

      font-size: 15px;

      @include respond(600px) {
        width: 100%;
        font-size: 14px;
      }
    }

    .new-badge {
      background-color: #22428f;
      border-radius: 50px;
      color: #ffffff;
      padding: 10px 16px;
      font-size: 14px;

      font-weight: 600;
      white-space: nowrap;

      @include respond(600px) {
        padding: 10px 12px;
        font-size: 12px;
      }
    }

    .link-text {
      white-space: nowrap;
      margin-left: 20px;
      color: #000000;

      @include respond(600px) {
        margin-left: 6px;
        line-height: 24px;
        white-space: normal;
      }
    }

    .text-content {
      width: 45%;

      h2 {
        font-weight: 400;
        font-size: 86px;
        line-height: 88px;
        letter-spacing: -0.02em;
        color: #22428f;
        text-align: left;
        margin-bottom: 50px;
        white-space: nowrap;
      }

      span {
        font-weight: 800;
      }

      .span-2 {
        color: #febc11;
      }

      img {
        width: 100%;
        object-fit: contain;
      }

      .info-container {
        text-align: left;
        margin-top: 40px;
        display: flex;
        gap: 10px;
      }

      .info-text {
        font-size: 20px;
        font-weight: 500;
        white-space: nowrap;
      }

      .info-link {
        font-size: 20px;
        color: #22428f;
        font-weight: 600;
        text-decoration: none;
        white-space: nowrap;
      }

      .web-hidden {
        display: none;
      }
      .mobile-hidden {
        display: block;
      }
      .cta-items {
        justify-content: flex-start;
        margin-top: 4rem;
      }

      @include respond(1300px) {
        h2 {
          font-size: 60px;
          line-height: 68px;
        }
      }
      @include respond(1000px) {
        width: 100%;

        .web-hidden {
          display: block;
        }
        .mobile-hidden {
          display: none;
        }

        h2 {
          font-size: 50px;
          line-height: 68px;
        }

        .info-text {
          font-size: 16px;
        }

        .info-link {
          font-size: 16px;
        }
      }

      @include respond(600px) {
        h2 {
          font-size: 40px;
          line-height: 48px;
        }
        .info-container {
          display: block;
        }
      }

      .countries-web {
        @include respond(1000px) {
          display: none;
        }
      }
      .countries-mobile {
        display: none;
        @include respond(1000px) {
          display: block;
          width: 500px;
        }

        @include respond(700px) {
          display: block;
          width: 90%;
        }
      }
    }

    .download-mobile {
      display: none;

      @include respond(1015px) {
        display: flex;
        width: 50%;
        margin-top: 60px;
        justify-content: flex-start;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: #194ca0;
          color: #fff;
          height: 56px;
          font-size: 20px;
          font-weight: 500;
          text-decoration: none;
          border-radius: 10px;
        }
      }
      @include respond(600px) {
        width: 100%;
      }
    }

    .images-content {
      width: 45%;
      position: relative;

      @include respond(1015px) {
        display: none;
      }

      .qrcode {
        width: 100%;
        height: 460px;
        object-fit: contain;
      }

      .absolute-badge1 {
        position: absolute;
        height: 200px;
        object-fit: contain;
        bottom: -100px;
        left: -30px;
      }
      .absolute-badge2 {
        position: absolute;
        height: 150px;
        object-fit: contain;
        bottom: -70px;
        right: 30px;
      }
      .absolute-badge3 {
        position: absolute;
        height: 70px;
        object-fit: contain;
        top: 70px;
        left: 80px;
      }

      @include respond(1400px) {
        .qrcode {
          height: 350px;
        }
        .absolute-badge1 {
          height: 130px;
          bottom: -50px;
          left: -30px;
        }
        .absolute-badge2 {
          height: 100px;
          bottom: -30px;
          right: 30px;
        }

        .absolute-badge3 {
          left: 50px;
        }
      }
    }

    .flexible-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      white-space: nowrap;

      .text-contents {
        text-align: right;
        width: 33%;

        p {
          font-size: 14px;
          line-height: 32px;
          font-weight: 400;
          margin: 2rem 0 0;
          color: #22428f;
          word-spacing: 0.4rem;
        }

        h1 {
          color: #22428f;
          font-size: 5rem;
          font-weight: 400;
          text-align: right;
        }
      }
      @include respond(1310px) {
        .text-contents {
          text-align: center;
          width: 30%;

          h1 {
            font-size: 4rem;
          }
        }
      }
      @include respond(1038px) {
        padding: 30px;
        .text-contents {
          h1 {
            font-size: 3.5rem;
          }
        }
      }
      @include respond(1015px) {
        flex-direction: column;
        padding: 30px 0;
        .text-contents {
          width: 100%;
          text-align: center;

          h1 {
            font-size: 4rem;
            text-align: center;
          }
        }
      }
      .flex-banner {
        width: 100%;
        display: none;
        img {
          width: 100%;
          height: 400px;
          object-fit: cover;
        }

        @include respond(1000px) {
          display: block;
        }
        @include respond(500px) {
          img {
            height: 300px;
          }
        }
      }
      .app-btn {
        width: 29%;
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        a {
          text-decoration: none;
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          padding: 20px 30px;
          border: 4px solid #22428f;
          background-color: #22428f;
          border-radius: 14px;
        }
        @include respond(1310px) {
          width: 25%;
        }
        @include respond(1000px) {
          justify-content: center;
          width: 80%;
          a {
            font-size: 18px;
            padding: 15px 20px;
          }
        }
      }
    }
  }

  .cta {
    margin: 5rem 2rem;

    h1 {
      font-size: 3.5rem;
      font-weight: 600;
      color: #22428f;
      padding-top: 1.8rem;
      text-align: left;
    }

    p {
      font-size: 2rem;
      line-height: 32px;
      font-weight: 400;
      margin: 2rem 0 0;
      text-align: left;
    }

    .cta-items {
      justify-content: flex-start;
      margin-top: 4rem;
    }

    .cta-item:first-child {
      margin-left: 0;
    }
  }

  .quotes {
    margin: 4rem 1rem;
  }

  &__contact-sales {
    border: 2px solid red;
    background: #f6f9ff;
    padding: 50px 10px;
  }

  &__banner-info {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f9ff;
    padding: 50px 10px;

    .staff-1 {
      position: absolute;
      left: -10px;
      top: -20px;
      z-index: 0;

      @include respond(1000px) {
        top: -50px;
        left: -60px;
      }
    }
    .staff-2 {
      position: absolute;
      right: 0;
      bottom: -100px;
      z-index: 0;
      transform: scaleY(-1);

      @include respond(1000px) {
        bottom: -80px;
      }
    }

    &.banner-cta {
      width: 100%;
      display: flex;
    }

    .banner-info-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }
    .banner-info-items {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      .banner-info-item-1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: #1f3c84;
        margin: 0 0 15px 0;
        border-radius: 8px;
        padding: 20px;
        background-image: url('../../assets/new-assets/patterned-background.svg');

        p {
          font-size: 2rem;
          font-weight: 600;
          line-height: 34px;
          color: #ffffff;
          text-align: left;
        }

        img {
          width: 260px;
          @include respond(1000px) {
            width: 160px;
          }
        }
      }

      .banner-info-item-2 {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        background: #fad206;
        margin: 0 0 15px 0;
        border-radius: 8px;
        padding: 30px;
        background-image: url('../../assets/new-assets/patterned-background.svg');

        p {
          font-size: 2rem;
          font-weight: 600;
          line-height: 20px;
          color: #22428f;
          text-align: left;
          margin-left: 20px;
        }

        img {
          width: 150px;
        }
      }

      .banner-info-item-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: #755cff;
        margin: 0 0 15px 0;
        border-radius: 8px;
        background-image: url('../../assets/new-assets/patterned-background.svg');

        p {
          font-size: 2rem;
          font-weight: 600;
          line-height: 30px;
          color: #ffffff;
          text-align: left;
          padding: 54px 40px;
        }

        img {
          width: 50%;
          height: 75%;
        }
      }
    }

    .services {
      // margin-top: 10rem;
      padding: 15px;
    }

    .services-items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1%;

      .services-item {
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 8px;
        padding: 20px;
        margin: 10px 0;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      }
    }
  }

  &__how-it-works {
    background: #1c397d;
    display: flex;
    align-items: center;

    .how-it-works-contents {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0 5rem;
    }

    h3 {
      color: #ffffff;
      margin: 10% 0;
    }

    .how-it-works-items {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
    }

    .how-it-works-item-text {
      width: 100%;
      color: #ffffff;
      cursor: pointer;

      .text {
        padding: 2rem 0;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        pointer-events: none;

        h4 {
          font-size: 2.5rem;
          line-height: 24px;
          font-weight: 600;
          padding-bottom: 15px;
        }

        p,
        li {
          font-size: 1.6rem;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }

      .yellow-border-left {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        pointer-events: none;

        h4 {
          font-size: 2.5rem;
          line-height: 24px;
          font-weight: 600;
          padding-bottom: 15px;
        }

        p,
        li {
          font-size: 1.6rem;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }
    }

    .how-it-works-item-image {
      display: none;
    }
  }

  &__shipping-experience {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f6f9ff;

    .shipping-experience {
      margin: 6rem 1.5rem;

      h3 {
        font-size: 4rem;
        line-height: 42px;
        width: 100%;
      }
    }

    .shipping-experience-items {
      width: 95%;
      margin: 0 auto;
      display: flex;
      overflow: scroll;

      .shipping-experience-item-container {
        display: flex;
        padding: 1.5rem;
      }
    }
  }

  .carousel-arrows {
    width: 40%;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    gap: 4rem;
  }
  .shipping-experience-item {
    background: white;
    border-radius: 8px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 20px 10px;
    min-width: 150px;
    height: 200px;
    flex: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    img {
      margin: 20px 0px;
    }
    .bold-text {
      text-align: left;
      font-size: 1.55rem;
      line-height: 2rem;
    }
  }

  &__testimonial {
    padding: 2.5%;
    background-image: url(../../components/new-home-page/assests/bg-for-testimonials.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .testimonial__quote__icon {
      display: none;

      @media screen and (min-width: 48rem) {
        height: 25.9rem;
        width: 40rem;
        display: block;
        position: absolute;
        top: 2rem;
        right: 2rem;
      }
    }
    background-blend-mode: overlay;

    .testimonial-contents {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 12px;
      overflow: hidden;
    }

    .header-title {
      color: #fff;
      font-size: 48px;
      font-weight: 700;
      text-align: left;
      margin-bottom: 50px;

      @include respond(medium) {
        font-size: 32px;
        padding-top: 40px;
        padding-left: 5px;
      }
    }

    #testimonial-carousel-selectors {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 32px;
      height: 120px;
      padding: 5px;
      border-radius: 30px;
      background: #ffffff0d;
      margin-right: 5px;

      * {
        cursor: pointer;
      }

      .testimonial-carousel-selector {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #ffffff;
      }

      .testimonial-carousel-not-selected {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #aab5d1;
      }
    }

    .testimonial-content-video {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include respond(medium) {
        height: 600px;
      }
      @include respond(457px) {
        height: 400px;
      }

      .testimonial-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      button {
        align-self: flex-end;
        border: 0;
        outline: 0;
        border-radius: 20px;
        padding: 5px 15px;
        color: white;
        background: #000;
        display: flex;
        align-items: center;
        gap: 4px;
        transform: translate(0%, -135%);
        font-size: 16px;
        background-color: rgba(18, 18, 18, 0.8);
        margin-right: 16px;
        margin-bottom: 14px;

        img {
          transform: translateY(15%);
        }
      }
    }

    .testimonial-content-details {
      width: 100%;
      display: flex;
      align-items: center;
      background: #ffffff1a;
      backdrop-filter: blur(2px);
      @include respond(medium) {
        flex-direction: column;
        padding: 10px 16px;
      }
    }

    .testimonial-details {
      width: 100%;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 37px;
      padding: 2rem 0;

      .test-logo {
        width: 80px;
        height: 80px;
      }
      @include respond(medium) {
        margin: 0;

        .test-logo {
          width: 40px;
          height: 40px;
          margin-top: 20px;
        }
      }
      p {
        font-size: 1.75rem;
      }

      .testimonial-caption {
        margin-bottom: 3rem;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        padding: 10px 20px;
      }

      .testimonial-merchant-contents {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond(800px) {
          height: 400px;
        }
      }

      .testimonial-description {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 32px;
        text-align: left;
      }

      .testimonial-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include respond(1100px) {
          flex-direction: column;
        }
      }
      .testimonial-author {
        font-size: 24px;
        font-weight: 600;
        font-style: normal;
        line-height: 24px;
        text-align: left;
        margin-right: auto;
        margin-left: auto;
        color: #fff;
      }
      .testimonial-company {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        padding: 10px 0px;
        margin: 0;
      }

      .playlist-text {
        color: #ffd708;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .testimonial-selectors {
      @include respond(medium) {
        transform: rotate(270deg);
      }
    }
  }

  &__faq {
    display: flex;
    align-items: center;

    .faq-contents {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 5rem 2rem;
      align-items: baseline;
    }

    .faq-content-text {
      width: 100%;
      text-align: left;
      padding-bottom: 3rem;

      h3 {
        margin-bottom: 2rem;
        font-style: normal;
        font-weight: 600;
        font-size: 4.8rem;
        line-height: 110%;
        letter-spacing: -0.02em;
        color: #22428f;
      }

      p {
        font-size: 2.5rem;
        line-height: 30px;
      }
    }

    .faq-content-faqs {
      width: 100%;
      padding: 0 1.8rem;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
    }

    .faq-item {
      padding: 24px 0 32px 0;
      text-align: left;
      border-bottom: 1px solid #e2e4e8;
      cursor: pointer;
      .faq-item-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 5%;
      }

      h5 {
        font-size: 1.8rem;
        font-weight: 600;
        margin: 0;
      }
      &:nth-child(1):not(.chosen) {
        @media screen and (min-width: $tablet_maximum) {
          padding-top: 0;
        }
      }
      &.chosen {
        padding-bottom: 0;
        h5 {
          margin-bottom: 1.75rem;
        }
      }

      p {
        font-size: 1.75rem;
        line-height: 30px;
        margin-bottom: 4rem;
      }

      .faq-item-accordion-button {
        cursor: pointer;
      }
    }
  }

  &__bottom-cta {
    display: flex;
    align-items: center;
    background: #f6f9ff;

    h3 {
      text-align: center;
      font-size: 4rem;
      font-weight: 600;
      line-height: 40px;
    }

    .bottom-cta-contents {
      margin: 0 auto;
      padding: 50px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__link-button {
    text-decoration: none;
    color: #22428f;

    &.bttn-yellow {
      background: #ffd708;
      color: #22428f;
    }

    &.bttn-light-blue {
      background: #e8eeff !important;
      border: 1px solid #c5d6ff;
      color: #22428f !important;
    }

    &.bttn-blue {
      background: #22428f;
      color: #ffffff;
    }

    &.bttn-white {
      background: #ffffff;
      color: #22428f;
      box-shadow: 0px 0px 0px 2px #22428f inset;
    }

    &.bttn-lg {
      width: 180px;
      min-width: 180px;
      height: 48px;
      padding: 12px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22.4px;
      border-radius: 8px;
    }

    &.bttn-sm {
      width: 120px;
      min-width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      border-radius: 8px;
    }
  }

  &__our-partners {
    background-color: #fff;
    padding: 70px 6.675% 70px 6.675%;
    text-align: center;
    @include respond($desktop_minimum) {
      padding: 50px 6.675% 50px 6.675%;
    }

    .partners-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }

    .header {
      margin-top: 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 5rem;
      line-height: 58px;
      text-align: center;
      letter-spacing: -0.02em;
      background-color: transparent;
      padding: 0;
      color: #22428f;
      @include respond($desktop_average) {
        font-size: 40px;
        line-height: 50px;
      }
      @include respond($tablet_maximum) {
        font-size: 3.5rem;
        line-height: 40px;
      }
    }
    .partner-logo-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respond($tablet_maximum) {
        flex-wrap: wrap;
      }
      .image-container {
        width: 25%;
        @include respond($tablet_maximum) {
          width: 50%;
        }
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .image-container:nth-child(3),
      .image-container:nth-child(4) {
        @include respond($tablet_maximum) {
          margin-top: 30px;
        }
      }
    }
  }

  &__plugin {
    background-image: url('../../assets/new-assets/patterned-background.svg');
    background-color: #22428f;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 6.675%;
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 50px 6.675%;
    }
    a {
      text-decoration: none;
    }

    .plugin-content {
      width: 60%;
      text-align: left;
      padding-right: 4rem;

      @include respond($desktop_minimum) {
        width: 100%;
        padding-right: 0;
      }
      .header {
        margin-top: 2.4rem;
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 5.8rem;
        text-align: left;
        letter-spacing: -0.02em;
        background-color: transparent;
        padding: 0;
        color: #ffffff;

        @media screen and (min-width: 48rem) {
          font-size: 4.8rem;
          line-height: 5rem;
        }
      }

      p {
        font-size: 2rem;
        line-height: 32px;
        font-weight: 500;
        margin: 2rem 0 0;
        color: #ffffff;
        padding-right: 17%;
        @include respond($desktop_minimum) {
          padding-right: 0;
        }
      }

      .learn-more {
        font-size: 16px;
        font-weight: 700;
        background-color: #ffd708;
        outline: none;
        border: none;
        color: #22428f;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.8rem;
        width: 255px;
        height: 48px;
        margin-top: 27px;
        @include respond($tablet_maximum) {
          width: 175px;
          height: 46px;
        }
      }
      .tag {
        font-size: 14px;
        font-weight: 700;
        color: #ffd708;
      }
    }

    .image-container {
      width: 32%;
      @include respond($desktop_minimum) {
        width: 70%;
        margin-top: 50px;
      }
      @include respond($tablet_maximum) {
        width: 100%;
      }

      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__api-doc {
    background-color: #f8fbff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 6.675% 70px 6.675%;

    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 50px 6.675% 50px 6.675%;
    }
    a {
      text-decoration: none;
    }

    .api-content {
      width: 60%;
      text-align: left;
      padding-right: 4rem;

      @include respond($desktop_minimum) {
        width: 100%;
        padding-right: 0;
      }
      .header {
        margin-top: 2.4rem;
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 5.8rem;
        text-align: left;
        letter-spacing: -0.02em;
        background-color: transparent;
        padding: 0;
        color: #22428f;

        @media screen and (min-width: 48rem) {
          font-size: 4.8rem;
          line-height: 5rem;
        }
      }

      p {
        font-size: 2rem;
        line-height: 32px;
        font-weight: 500;
        margin: 2rem 0 0;
        color: black;
        padding-right: 17%;
        @include respond($desktop_minimum) {
          padding-right: 0;
        }
      }

      .learn-more {
        font-size: 16px;
        font-weight: 700;
        background-color: #22428f;
        outline: none;
        border: none;
        color: #ffffff;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 255px;
        height: 48px;
        margin-top: 27px;
        @include respond($tablet_maximum) {
          width: 175px;
          height: 46px;
        }
      }
      .tag {
        font-size: 14px;
        font-weight: 700;
        color: #a459fe;
      }
    }

    .image-container {
      width: 30%;
      @include respond($desktop_minimum) {
        width: 70%;
        margin-top: 50px;
      }
      @include respond($tablet_maximum) {
        width: 100%;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &__mobile-app {
    background: #ffd708;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 6.675%;
    position: relative;
    z-index: 100;
    @include respond($desktop_minimum) {
      flex-direction: column;
      padding: 0 0 0 0;
      height: auto;
    }
    a {
      text-decoration: none;
    }

    .mobile-content {
      width: 80%;
      text-align: left;
      padding-right: 4rem;

      @include respond(1200px) {
        width: 70%;
        padding-right: 4rem;
      }

      @include respond($desktop_minimum) {
        width: 100%;
        padding: 50px 6.675% 0 6.675%;
      }
      .header {
        font-style: normal;
        font-weight: 600;
        font-size: 3.2rem;
        line-height: 5rem;
        text-align: left;
        letter-spacing: -0.02em;
        background-color: transparent;
        padding: 0;
        color: #22428f;
        @media screen and (min-width: 48rem) {
          font-size: 4.5rem;
        }
      }

      p {
        font-size: 2rem;
        line-height: 32px;
        font-weight: 500;
        margin: 2rem 0 0;
        color: #224391;
        padding-right: 17%;
        @include respond($desktop_minimum) {
          padding-right: 0;
        }
      }

      .learn-more {
        font-size: 16px;
        font-weight: 700;
        background-color: #224391;
        outline: none;
        border: none;
        color: #fff;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 255px;
        height: 48px;
        margin-top: 27px;
        @include respond($tablet_maximum) {
          width: 175px;
          height: 46px;
        }
      }
      .tag {
        font-size: 14px;
        font-weight: 700;
        color: #a459ff;
      }
    }

    .web-scan {
      display: block;
      @include respond($desktop_minimum) {
        display: none;
      }
    }

    .mobile-app {
      display: none;
      @include respond($desktop_minimum) {
        display: block;
      }
    }

    .show-break {
      @include respond($desktop_minimum) {
        display: none;
      }
    }

    .barcode-container {
      border-radius: 50%;
      height: 289px;
      width: 289px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      img {
        width: 100%;
        position: relative;
        height: inherit;
        right: 0;
        bottom: 0;
        object-fit: contain;
      }

      @include respond($desktop_minimum) {
        display: none;
      }
    }

    .image-container {
      display: none;
      width: 74%;
      position: relative;
      right: 4%;
      height: 100%;
      @include respond($desktop_minimum) {
        width: 100%;
        margin-top: 30px;
        right: 0;
        display: block;
      }
      img {
        width: 100%;
        position: relative;
        height: inherit;
        right: 0;
        bottom: 0;
        object-fit: contain;
      }
    }
    &__download {
      width: 75%;
      margin-top: 2rem;
      cursor: pointer;
      @include respond(mini-mobile) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: #ffffffb2;
    background-color: #22428f;
    background-image: url('../../assets/new-assets/patterned-background.svg');
    @include respond(1084px) {
      font-size: 14px;
    }
    .footer-logo {
      width: 120px;
      height: 28px;
      margin-bottom: 30px;
    }

    li {
      list-style: none;
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;
      color: #ffffffb2;
      display: flex;
    }

    .footer-content-items {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 100px 0;

      @include respond(789px) {
        padding: 50px 0;
      }
    }

    .help-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include respond(789px) {
        display: block;
      }
    }

    .help-item {
      // width: 33%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include respond(1000px) {
        margin-bottom: 40px;
      }

      span {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }

      a {
        text-decoration: underline;
        color: #9bb8ff;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .mobile-version {
      display: none;
      @include respond(1000px) {
        display: block;
      }
    }

    .web-version {
      display: block;
      margin-bottom: 60px;
      @include respond(1000px) {
        display: none;
      }
    }

    .footer-content-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include respond(789px) {
        display: block;
      }
    }

    .footer-content-item-newsletter {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .newsletter-content {
        width: 40%;
      }

      .footer-button {
        margin-top: 10px;
        width: 100%;
        height: 48px;
        padding: 0 20px;
        background: #ffd708;
        border-radius: 8px;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: #22428f;
        border: 1px solid #ffd708;
        &:disabled {
          opacity: 0.4;
        }
      }

      .input-field input {
        width: 100%;
        border: 1px solid #ffffffb2;
        padding: 12px 9px;
        border-radius: 8px;
        color: #ffffffb2;
        background: transparent;
      }
      .name-input {
        width: 44%;
        margin-left: 0 !important;
      }
      .email-input {
        width: 54%;
        margin-left: 0 !important;
      }
      .input-field input::placeholder {
        color: #ffffffb2;
      }

      @include respond(1100px) {
        flex-direction: column;
        .newsletter-content {
          margin-bottom: 50px;
        }

        .newsletter-content {
          width: 100%;
        }
      }

      @include respond(700px) {
        .input-field {
          width: 100%;
        }
        .input-field input {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }

        .footer-button {
          width: 100%;
        }
      }
    }

    .footer-content-item:first-child {
      border-bottom: 1px solid #ffffffb2;
      margin-bottom: 30px;
    }
    .footer-content-item:nth-child(2),
    .footer-content-item:nth-child(3) {
      @include respond(mobile) {
        flex-direction: column;
        align-items: center;
        ul {
          padding: 0;
          gap: 5%;
          flex-wrap: wrap;
        }
        li {
          margin-left: 0;
        }
      }
    }

    .content-item-columns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      list-style: none;
      text-align: left;
      padding: 0;
      margin-bottom: 0;

      .column-items {
        ul {
          padding-left: 0;

          width: 100%;
        }

        @include respond(1000px) {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0;
          }
        }
        li {
          padding-left: 0;
          font-size: 18px;
        }
      }

      .delivery-details {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        ul,
        li {
          padding-left: 0;
        }
      }

      .topship-text {
        width: 24%;

        @include respond(1440px) {
          width: 30%;
        }
        @include respond(1000px) {
          width: 100%;
        }
      }

      @include respond(1000px) {
        flex-direction: column;

        .delivery-details {
          width: 100%;
          margin-top: 20px;
        }
      }
      @include respond(600px) {
        .topship-text {
          width: 100%;
        }

        .delivery-details {
          flex-direction: column;
        }
      }
    }
    .column-item-header {
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .topship-footer-text {
      font-size: 18px;
      line-height: 24px;
      // white-space: nowrap;
    }

    .email-listing-form {
      display: flex;
      align-items: flex-start;
      width: 100% !important;
      @media (min-width: $desktop_minimum) {
        width: unset !important;
      }
    }
    #mc_embed_signup {
      margin: 0 !important;
      @media (min-width: $desktop_minimum) {
        width: unset !important;
      }
    }
    #mc_embed_signup_scroll .form-controls {
      // gap: 1em;
      flex-direction: column;
      align-items: center;
      margin: 0;
    }
    .mc-field-group {
      clear: unset !important;
      width: 100% !important;
      padding-bottom: 0 !important;
      min-height: unset !important;
      margin-right: 0 !important;
    }

    .subscribe-email {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      input:nth-child(2) {
        margin-top: 1em;
      }

      input {
        width: 230px !important;
        border: 1px solid #ffffffb2 !important;
        padding: 12px 9px !important;
        border-radius: 8px !important;
        color: #ffffffb2 !important;
        background: #22428f !important;
        box-shadow: unset !important;
        &:focus,
        &:focus-visible,
        &:focus-within {
          outline: 0 !important;
        }
        @media (min-width: $tablet_average) and (max-width: $desktop_minimum) {
          width: 200px !important;
        }
        @media (max-width: $tablet_average) {
          width: 100% !important;
        }
      }
      input::placeholder {
        color: #ffffffb2 !important;
      }
    }

    .subscribe-button {
      button {
        margin: 0 !important;
        width: 230px !important;
        height: 48px !important;
        padding: 0 !important;
        background: #ffd708 !important;
        border-radius: 8px !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 140% !important;
        color: #22428f !important;
        border: 1px solid #22428f !important;
        &:disabled {
          opacity: 0.4;
        }
        @include respond(1024px) {
          width: 200px !important;
        }
        @include respond(550px) {
          width: 100% !important;
        }
      }
    }

    .input-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .rights-reserved-contents {
      display: flex;

      @include respond(600px) {
        flex-wrap: wrap;
      }

      li {
        margin-left: 20px;
      }
    }
    .footer-socials {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 30%;

      img {
        width: auto;
        height: auto;
        object-fit: contain;
      }
      @include respond(1100px) {
        width: 70%;
      }
      @include respond(550px) {
        justify-content: space-around;
        width: 100%;
      }
    }
    .footer-social-item {
      align-items: center;
      background-color: #194ca0;
      background-color: var(--color-blue-white);
      border-radius: 50%;
      display: flex;
      height: 58px;
      justify-content: center;
      padding: 2.5rem;
      width: 58px;

      img {
        width: 40px;
      }
    }
    &__cta {
      padding: 128px 0;

      @include respond($tablet_maximum) {
        padding: 60px 6.675%;
      }
      background: #f6f9ff;
      &__header {
        margin: 0 20.05% 3.225% 20.05%;
        @include respond($tablet_maximum) {
          margin: 0 10% 1.5% 10%;
        }
        @include respond($standard_mobile) {
          margin: auto;
        }
        // margin: 0 250px 40px 250px;
      }

      &__header * {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #22428f;
        @include respond($desktop_average) {
          font-size: 40px;
          line-height: 50px;
        }
        @include respond($tablet_maximum) {
          font-size: 3.2rem;
          line-height: 38px;
        }
      }
      &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-top: 40px;

        @include respond($standard_mobile) {
          flex-direction: column;
        }
        a {
          border: 0;
          outline: 0;
          border-radius: 8px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #22428f;
          text-decoration: none;
          width: 180px;
          height: 54px;

          @include respond($standard_mobile) {
            width: 100%;
          }
        }
        a:first-child {
          background: transparent;
          border: 2px solid #22428f;
        }
        a:last-child {
          background: #ffd708;
        }
      }
    }
  }
  &__country-list {
    margin: 10rem 0;
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.slick-slide > div:focus-visible {
  border: none !important;
  outline: none !important;
}

@media only screen and (min-width: $tablet_maximum) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

  .new-home-page {
    .homepage-container {
      width: 100%;
      min-width: 80%;
      margin: 0 2rem;
      display: flex;
    }

    &__banner {
      display: flex;
      margin: 10rem 0 5rem 0;
    }

    .cta {
      width: 60%;
      padding-right: 4rem;

      .cta-contents {
        text-align: left;

        h1 {
          font-size: 4.8rem;
        }

        p {
          font-size: 2rem;
        }
      }

      .cta-items {
        justify-content: flex-start;
      }
    }

    .quotes {
      width: 40%;
    }

    .banner-info-items {
      flex-direction: row;
    }

    .col-items {
      padding: 1rem;
    }

    .col-items:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .banner-info-item-1,
    .banner-info-item-2 {
      flex: 1;
      padding: 5rem;
    }

    .banner-info-items .banner-info-item-3 p {
      padding: 20px;
    }

    .banner-info-items .banner-info-item-3 img {
      width: 66%;
      height: 75%;
    }

    .services-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .services-item {
        width: 32%;
      }
    }

    .shipping-experience {
      width: 95%;
      margin: 6rem 1.5rem;

      h3 {
        font-size: 4rem;
        line-height: 48px;
        width: 100%;
      }
    }

    .shipping-experience-items {
      width: 100%;
    }

    .shipping-experience-item-container {
      width: 100%;
    }

    .shipping-experience-item {
      min-width: 250px;
    }

    &__testimonial {
      .homepage-container {
        margin: 0;
      }

      .testimonial-contents {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 780px;
      }

      .testimonial-content-video {
        width: 100%;
        height: 100%;
      }

      .testimonial-details {
        width: 100%;
        height: 90%;
      }

      .testimonial-merchant-contents {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      testimonial-merchant-contents {
        height: 100%;
        justify-content: flex-start;
        align-items: center;
      }

      .testimonial-selectors {
        justify-content: center;
      }
    }

    &__faq {
      .faq-contents {
        width: 100%;
        flex-direction: row;
        padding: 10rem 2rem 2rem;
      }

      .faq-content-text {
        width: 80%;
        text-align: left;
        padding-right: 3rem;

        h3 {
          margin-bottom: 2rem;
          font-size: 3rem;
          line-height: 48px;
        }

        p {
          font-size: 2rem;
        }
      }
    }

    &__bottom-cta {
      h3 {
        width: 90%;
        font-size: 5rem;
      }
    }
  }
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .new-home-page {
    .homepage-container {
      width: 100%;
      min-width: 80%;
      margin: 0 94px;
      display: flex;
    }

    &__banner-info {
      display: flex;
      justify-content: center;
      // height: 1520px;
      background: #f6f9ff;
      padding-top: 128px;
      position: relative;

      &.banner-cta {
        width: 100%;
        display: flex;
      }

      .banner-info-item {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      .col-items {
        flex: 0;
      }
      .banner-info-items {
        display: flex;
        justify-content: center;
        margin: 0 auto;

        .banner-info-item-1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 760px;
          height: 417px;
          background-color: #1f3c84;
          margin: 0 15px 15px 0;
          border-radius: 8px;
          padding: 5rem;
          background-image: url('../../assets/new-assets/patterned-background.svg');

          p {
            font-size: 36px;
            font-weight: 600;
            line-height: 40px;
            color: #ffffff;
            text-align: left;
          }
        }

        .banner-info-item-2 {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
          width: 760px;
          height: 417px;
          background: #fad206;
          border-radius: 8px;
          padding: 5rem;
          background-image: url('../../assets/new-assets/patterned-background.svg');

          p {
            font-size: 32px;
            font-weight: 600;
            line-height: 38px;
            color: #22428f;
            text-align: left;
            margin-left: 20px;
          }

          img {
            width: 318px;
          }
        }

        .banner-info-item-3 {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 520px;
          height: 849px;
          background: #755cff;
          border-radius: 8px;
          background-image: url('../../assets/new-assets/patterned-background.svg');

          p {
            font-size: 32px;
            font-weight: 600;
            line-height: 38px;
            color: #ffffff;
            text-align: left;
            padding: 54px 40px;
          }

          img {
            width: 443px;
            height: 570px;
          }
        }
      }

      .cta-contents {
        text-align: left;

        h1 {
          font-size: 4.8rem;
        }
      }

      .services-items {
        display: flex;
        justify-content: space-between;

        .services-item {
          display: flex;
          flex-direction: column;
          background: white;
          width: 32%;
          border-radius: 8px;
          padding: 20px;

          p {
            font-size: 15px;
          }
        }
      }
    }

    &__how-it-works {
      background: #1c397d;
      display: flex;
      align-items: center;

      .how-it-works-contents {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      h3 {
        color: #ffffff;
        margin: 120px 0 80px 0;
      }

      .how-it-works-items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 10rem;
      }

      .how-it-works-item-text {
        width: 45%;
        min-width: 40%;
        color: #ffffff;
        padding: 3rem 0;
        cursor: pointer;

        .text {
          padding: 5rem 1rem 0rem 7rem;
          border-left: 2px solid whitesmoke;
          pointer-events: all;
        }

        .yellow-border-left {
          padding: 5rem 1rem 0rem 7rem;
          border-left: 4px solid yellow;
          pointer-events: all;
        }
      }

      .how-it-works-item-image {
        width: 850px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 10rem;
      }

      .image {
        width: 100%;
        background: whitesmoke;

        img {
          width: 100%;
        }
      }
    }

    &__shipping-experience {
      display: flex;
      align-items: center;
      background: #f6f9ff;

      .shipping-experience {
        margin: 10rem 0;
        width: 100%;

        h3 {
          width: 700px;
        }
      }

      .shipping-experience-items {
        display: flex;
        justify-content: space-between;
        overflow-x: scroll;
      }
    }
    .shipping-experience-item {
      background: white;
      min-width: 30%;
      height: 272px;
      border-radius: 8px;
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .small-image {
        margin-bottom: 60px;
      }

      .bold-text {
        text-align: left;
        font-size: 20px;
        line-height: 30px;
      }
    }

    &__testimonial {
      padding: 6.5%;
      // background-image: url('../../assets/images/testimonial-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
      background-blend-mode: overlay; /* Overlay blend mode */

      .homepage-container {
        margin: 0;
      }

      .testimonial-contents {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 780px;
      }

      .testimonial-content-video {
        width: 100%;
        height: 100%;
      }

      .testimonial-details .testimonial-description {
        font-size: 3.5rem;
        font-weight: 600;
        line-height: 48px;
      }

      .testimonial-details {
        width: 100%;
      }

      .testimonial-merchant-contents {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .testimonial-details .testimonial-author {
        font-size: 20px;
        font-weight: 500;
      }

      .testimonial-details .testimonial-company {
        font-size: 18px;
        font-weight: 400;
      }

      .testimonial-selectors {
        justify-content: center;
      }
    }

    &__faq {
      .faq-contents {
        width: 100%;
        flex-direction: row;
        padding: 10rem 2rem;
      }

      .faq-content-text {
        width: 80%;
        text-align: left;
        padding-right: 3rem;

        h3 {
          margin-bottom: 2rem;
          font-size: 5rem;
          line-height: 48px;
        }

        p {
          font-size: 3rem;
          line-height: 40px;
        }
      }
    }

    &__bottom-cta {
      display: flex;
      align-items: center;
      background: #f6f9ff;

      h3 {
        text-align: center;
      }

      .bottom-cta-contents {
        width: 60%;
        margin: 0 auto;
        padding: 120px 0;
      }
    }

    &__link-button {
      text-decoration: none;

      &.bttn-yellow {
        background: #ffd708;
        color: #22428f;
      }

      &.bttn-light-blue {
        background: #e8eeff !important;
        border: 1px solid #c5d6ff;
        color: #22428f !important;
      }

      &.bttn-blue {
        background: #22428f;
        color: #ffffff;
      }

      &.bttn-white {
        background: #ffffff;
        color: #22428f;
        box-shadow: 0px 0px 0px 2px #22428f inset;
      }

      &.bttn-lg {
        width: 180px;
        min-width: 180px;
        height: 48px;
        padding: 12px 24px;
        font-weight: 700;
        font-size: 16px;
        line-height: 22.4px;
        border-radius: 8px;
      }

      &.bttn-sm {
        width: 120px;
        min-width: 120px;
        height: 40px;
        padding: 12px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22.4px;
        border-radius: 8px;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: #ffffffb2;
      background-color: #22428f;
      background-image: url('../../assets/new-assets/patterned-background.svg');

      @include respond(1084px) {
        font-size: 14px;
      }
      .footer-logo {
        width: 120px;
        height: 28px;
        margin-bottom: 30px;
      }

      li {
        list-style: none;
        margin-bottom: 20px;
      }

      a {
        text-decoration: none;
        color: #ffffffb2;
      }

      .footer-content-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 128px 0;
      }

      .footer-content-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: flex-start;
      }

      .footer-content-item-newsletter {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        @include respond(1000px) {
          flex-direction: column;
        }
      }

      .footer-content-item:first-child {
        border-bottom: 1px solid #ffffffb2;
        margin-bottom: 30px;
      }

      .column-item-header {
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 20px;
      }

      .topship-footer-text {
        font-size: 14px;
        line-height: 24px;
      }

      .subscribe-email {
        input {
          width: 230px;
          border: 1px solid #ffffffb2;
          padding: 12px 9px;
          border-radius: 8px;
          color: #ffffffb2;
          background: #22428f;
          @media (min-width: $tablet_average) and (max-width: $desktop_minimum) {
            width: 200px !important;
          }
        }

        input::placeholder {
          color: #ffffffb2;
        }
      }

      .rights-reserved-contents {
        display: flex;

        li {
          margin-left: 20px;
        }
      }
    }
  }
}

/////// Animated Component
.animated__container {
  width: 100%;
  // padding: 2rem;
  background-color: #fff;
}

.animated__texts {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // height: 100vh;
  overflow: hidden;
  font-family: General Sans;
  font-size: 48px;
  font-weight: 600;
  line-height: 5.28rem;
  width: 48.6rem;
  // color: green;
  letter-spacing: -0.02em;
  text-align: left;
  height: 100%;
  padding: 11rem 0rem;
}

.animated__texts-two {
  width: 53.4rem;
  padding: 8rem 0rem;
}
.animated__texts-three {
  width: 53.4rem;
  padding: 8rem 0rem;
  color: #22428f;
}
.animated__texts span {
  //styleName: H2;

  display: inline-block;
  opacity: 0;
  transform: translateY(-100%);
  animation: fall 0.2s ease-out forwards;
  // transition: opacity 5s cubic-bezier(0.25, 1, 0.5, 1);
}

.text-item:nth-child(1) {
  animation-delay: 2s;
}
.text-item:nth-child(2) {
  animation-delay: 2.05s;
}

.text-item:nth-child(3) {
  animation-delay: 2.1s;
}

.text-item:nth-child(4) {
  animation-delay: 2.15s;
}

.text-item:nth-child(5) {
  // animation-delay: 0s;
  animation: fallTwo 0.2s ease-out forwards;
  animation-delay: 2.2s;
}

.text-item:nth-child(6) {
  animation: fallTwo 0.2s ease-out forwards;
  animation-delay: 2.25s;
}

.text-item:nth-child(7) {
  animation: fallTwo 0.2s ease-out forwards;
  animation-delay: 2.28s;
}
.text-item:nth-child(8) {
  animation: fallTwo 0.2s ease-out forwards;
  animation-delay: 2.31s;
}
.text-item:nth-child(9) {
  animation: fallTwo 0.2s ease-out forwards;
  animation-delay: 2.34s;
}
.text-item:nth-child(10) {
  animation: fallTwo 0.2s ease-out forwards;
  animation-delay: 2.37s;
}

@keyframes fall {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fallTwo {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.lottie-animation {
  height: 20rem;
}

.animated__container {
  height: 23rem;
}
@media screen and (min-width: 48rem) {
  .animated__container {
    height: 35rem;
  }
}

@media screen and (min-width: 62rem) {
  .animated__container {
    height: 56rem;
  }
}

// @keyframes fallThree {
//   0% {
//     opacity: 0;
//     transform: translateY(-2rem);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
