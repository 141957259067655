$tablet_minimum_index: 711px;
$tablet_minimum: 712px;
$tablet_maximum: 768px;
$desktop_minimum: 1024px;
$desktop_average: 1350px;

.delete-account {
    margin: 10% 30% 0% 30%;
    text-align: left;

    .bye-image {
        width: 70%;
    }

    .description {
        margin: 15px 0px;
        font-size: 16px;
        font-weight: 400;

        &-list {
            margin: 15px 0px 30px 0px;

            &-item {
                margin-bottom: 8px;
            }
        }

        .thanks {
            margin-bottom: 50px;
        }


        .actions {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 50px;
    
            .red {
                background-color: #C83F39;
                color: #fff;
                margin-right: 10px;
                border: none;
                border-radius: 8px;
                padding: 10px 20px;
            }
    
            .cancel {
                background-color: #fff;
                color: #000;
                border-radius: 8px;
                padding: 8px 20px;
                border: 1px solid #000;
            }
        }
    }

    @include respond(tablet) {
        margin: 30% 5% 0% 5%;

        .description {
            font-size: 12px;;
        }

        .actions {
            margin-bottom: 100px;
        }

        .thanks {
            margin-bottom: 30px;
        }

        .bye-image {
            width: 100%;
        }
    }
}