.gateway {
  font-family: 'General Sans', sans-serif;

  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 68px;
    text-align: center;
    letter-spacing: -0.02em;
    background-color: transparent;
    padding: 0;
    color: #000000;
    width: 85%;
    margin: 24px auto;

    @include respond(1390px) {
      width: 90%;
    }
    @include respond($desktop_average) {
      font-size: 40px;
      line-height: 54px;
    }
    @include respond($tablet_maximum) {
      font-size: 34px;
      line-height: 48px;
      width: 100%;
    }
    @include respond(600px) {
      font-size: 30px;
      line-height: 44px;
    }
  }

  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 32px auto 0;

    button {
      border-radius: 10px;
      font-size: 16px;
      font-weight: 700;
      width: 200px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #224391;
    }

    .button-yellow {
      background: #ffd708;
      border: 2px solid #ffd708;
      outline: none;
    }

    .button-blue {
      background-color: white;
      border: 2px solid #224391;
    }

    .email-input {
      width: 300px;
      height: 56px;
      padding: 10px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 10px;
    }
    .email-input::placeholder {
      font-size: 14px;
      color: #ccc;
    }

    @include respond(700px) {
      width: 100%;
      flex-direction: column;

      button {
        width: 100%;
      }

      .email-input {
        width: 100%;
      }
    }
  }

  &__intro {
    position: relative;
    background-color: white;
    padding: 170px 6.675% 100px 6.675%;
    @include respond($desktop_minimum) {
      padding: 100px 6.675% 100px 6.675%;
    }
    @include respond(500px) {
      padding: 50px 6.675% 100px 6.675%;
    }
    h1 {
      text-align: center;
      @include respond(600px) {
        text-align: left !important;
      }
    }

    p {
      text-align: center;
      color: #000000;
      font-size: 24px;
      line-height: 38px;
      font-weight: 500;
      margin: 3.4rem 18rem;

      @include respond(990px) {
        margin: 3.4rem 0;
        font-size: 20px;
        line-height: 34px;
      }

      @include respond(600px) {
        margin: 3.4rem 0;
        font-size: 2rem;
        line-height: 30px;
        text-align: left;
      }
    }
  }

  &__routes {
    .slick-dots {
      bottom: 20px !important;
    }

    .slick-prev:before,
    .slick-next:before {
      color: #22428f;
      font-size: 24px;
    }

    padding: 0 0 50px 0;
    background: linear-gradient(
      180deg,
      rgba(228, 238, 252, 0) 3.12%,
      #e4eefc 14.58%,
      rgba(228, 238, 252, 0.89576) 74.48%,
      rgba(228, 238, 252, 0.39) 97.92%
    );

    .routes-body {
      h1 {
        width: 80%;
        margin: 0 auto;

        @include respond($desktop_minimum) {
          width: 90%;
        }

        @include respond(600px) {
          width: 100%;
          padding: 0 2%;
          font-size: 24px;
        }
      }
    }
    .benefit-card {
      width: 80%;
      margin: 0 auto;

      @include respond($desktop_minimum) {
        width: 90%;
      }

      @include respond(600px) {
        width: 86%;
        padding: 0 2%;
      }
    }
    .routes-content {
      height: 673px;
      background: linear-gradient(
        112.8deg,
        #193170 17.17%,
        #1864aa 57.53%,
        rgba(50, 195, 236, 0) 118.07%
      );
      border-radius: 6px;
      position: relative;
      margin-top: 50px;
      display: flex !important ;
      justify-content: space-between;
      align-items: center;
      padding: 5% 0 5% 7%;
      z-index: 1000;

      @include respond($desktop_minimum) {
        padding: 5% 0 5% 5%;
      }

      @include respond(1200px) {
        padding: 5% 5%;
        height: 550px;
      }

      .transparent-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }

      h1 {
        @include respond(800px) {
          font-size: 24px;
          line-height: 34px;
        }
      }

      h4 {
        color: #ffffff;
        font-size: 2.8rem;
        line-height: 38px;
        font-weight: 600;
      }

      p {
        color: #ffffff;
        font-size: 2.2rem;
        line-height: 38px;
        font-weight: 400;
        margin-bottom: 3.4rem;
        margin-top: 2rem;

        @include respond(800px) {
          font-size: 2rem;
          line-height: 34px;
        }

        @include respond(500px) {
          font-size: 2rem;
          line-height: 30px;
        }
      }
      .button-white {
        border-radius: 10px;
        font-size: 18px;
        font-weight: 700;
        width: 200px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #000000;
        background-color: #ffffff;
        cursor: pointer;
        position: relative;
        z-index: 2000;
      }

      .text-content {
        width: 45%;
        text-align: left;

        @include respond(1200px) {
          width: 100%;
        }
      }

      .benefit-img-wrap {
        margin: 20px 0;

        @include respond(1200px) {
          display: none;
        }
      }

      .header-flex {
        display: flex;

        h4 {
          padding-right: 20px;
        }
      }

      .img {
        width: 40%;
        object-fit: contain;
        @include respond(800px) {
          display: none;
        }
      }
    }
  }

  &__faqs {
    padding: 50px 6.675% 0 6.675%;
    background-color: #ffffff;

    @include respond(1000px) {
      background: #d4e5ff;
    }

    .contact-heading {
      color: #22428f;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      line-height: 50px;
      letter-spacing: -0.02em;
      color: #000000;
      padding: 0 6%;

      @include respond(1000px) {
        font-size: 3rem;
        line-height: 40px;
        padding: 0;
      }
      @include respond(600px) {
        font-size: 2.8rem;
        line-height: 34px;
      }
    }
  }
}

.faq-content {
  position: relative;
  background: #e5effc;
  background-image: url(../../../src/components/new-home-page/assests/bg-for-faq.webp);
  background-position: top;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;

  padding: 4% 7%;

  @include respond(1000px) {
    padding: 8% 4% 4%;
  }

  h2 {
    color: #22428f;
    font-size: 3.2rem;
    font-weight: 600;
    text-align: center;
    line-height: 4.8rem;

    @media screen and (min-width: 48rem) {
      font-size: 4.8rem;
      line-height: 5.2rem;
    }
  }

  h4 {
    color: #ffffff;
    font-size: 2.8rem;
    line-height: 38px;
    font-weight: 600;
  }

  p {
    color: #ffffff;
    font-size: 2.5rem;
    line-height: 38px;
    font-weight: 400;
    margin: 2.4rem 0;
  }
  .button-white {
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
    width: 200px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000000;
    background-color: #ffffff;
  }

  .text-content {
    width: 50%;
    text-align: left;
  }
  .questions-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 60px;

    @include respond(1000px) {
      flex-direction: column;
      margin-top: 30px;
    }

    h5 {
      color: #22428f;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -2%;
      font-weight: 600;
      text-align: left;
      width: 60%;

      @include respond(1100px) {
        font-size: 20px;
      }
    }

    .show {
      display: block;
    }

    .hide {
      display: none;
    }
    .faq-items {
      position: relative;
      z-index: 1;
      width: 60%;
      @include respond(1000px) {
        width: 100%;
        margin-top: 30px;
      }
    }
    p {
      font-size: 2rem;
      color: #545859;
      line-height: 34px;
      text-align: left;
    }

    .faq-item {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
    }

    .faq-item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;

      img {
        width: 16px;
        @include respond(500px) {
          width: 12px;
        }
      }
    }
  }

  .img {
    position: absolute;
    right: 0;
    bottom: -50px;
    object-fit: contain;
    z-index: 0;
  }
}
