.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.mt-7 {
  margin-top: 7rem !important;
}
.mt-8 {
  margin-top: 8rem !important;
}
.mt-9 {
  margin-top: 9rem !important;
}
.mt-10 {
  margin-top: 10rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-5 {
  margin-left: 5rem !important;
}
.ml-6 {
  margin-left: 6rem !important;
}
.ml-7 {
  margin-left: 7rem !important;
}
.ml-8 {
  margin-left: 8rem !important;
}
.ml-9 {
  margin-left: 9rem !important;
}
.ml-10 {
  margin-left: 10rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}
.mb-6 {
  margin-bottom: 6rem !important;
}
.mb-7 {
  margin-bottom: 7rem !important;
}
.mb-8 {
  margin-bottom: 8rem !important;
}
.mb-9 {
  margin-bottom: 9rem !important;
}
.mb-10 {
  margin-bottom: 10rem !important;
}
.disable-element {
  pointer-events: none;
  opacity: 0.7;
}

.show-on-scroll {
  animation: slideInUp 1.5s !important;
}
.faq--default {
  animation: slideOut 1.5s !important;
}
