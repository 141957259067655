.set-location-details {
  position: absolute;
  top: 10px;
}

.location-details {
  width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 150px;
  z-index: 9;
  background-color: #fff;
}

.city,
.country {
  width: 100%;
  margin: 0 10px;
  text-align: left;
}

.city > span,
.country > span {
  display: flex;
}
