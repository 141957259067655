.home {
  overflow-x: hidden;

  .mailchimp {
    // background: rgba(255, 255, 255, 0.12);
    background: rgba(196, 196, 196, 0.12);
    padding: 4rem 0;

    h3 {
      // font-weight: bold;
      padding: 0;
      margin-bottom: 2rem;
      font-size: 2.5rem;
      color: #000;
      text-align: center;
    }

    .indicates-required {
      text-align: right;
      width: 920px;
      margin: 0 auto;
    }

    .asterisk {
      color: #e85c41;
      font-size: 150%;
      font-weight: normal;
      position: relative;
      top: 5px;
    }

    @include respond(mobile) {
      width: 95%;
      margin: 0 auto;
    }
  }

  &__intro {
    background-image: url("../../assets/images/topship_map.png");
    padding: 0px 4rem;
    height: 100vh;
    padding-top: 1vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 120px;
    background-position-x: center;
    margin-top: 10px;

    @include respond(tablet) {
      flex-direction: column;
      background-position-y: 120px;
      padding: 8vw 2rem;
    }

    @include respond(mobile) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 80vh;
      background-position-x: center;
      padding: 0.5rem 2rem;
      margin: none;
    }

    & > figure {
      position: absolute;
      bottom: -16vw;

      & img {
        width: 100vw;
      }

      @include respond(tablet) {
        left: 0;
        bottom: -16vw;
      }
    }
  }

  &__content {
    margin-top: 30px;

    h1 {
      font-weight: 800;
      font-size: 36px;

      span {
        color: var(--color-blue-dark);
      }

      @include respond(mobile) {
        font-size: 32px;
      }
    }

    &__description {
      display: flex;
      justify-content: center;

      p {
        line-height: 1.8;
        font-size: 18px;
        width: 70%;
        font-weight: 500;

        @include respond(mobile) {
          display: none;
        }
      }

      .show-on-mobile {
        display: none;

        @include respond(mobile) {
          display: block;
          border-radius: 5px;
          width: fit-content;
          width: 150px;
          background: #ffc50f;
          justify-content: center;
          align-items: center;
          font-size: 2.5rem;
          font-weight: bold;
          border: none;
          border-radius: 40px;
          line-height: 2;
          cursor: pointer;
          color: #003896;
          margin: 10px;
        }
      }
    }

    &__heading {
      color: var(--color-blue-dark) !important;
      font-weight: 700;
      width: fit-content;
      margin: auto;
      font-size: 2.4rem;

      @include respond(tablet) {
        font-size: 2.2rem;
      }

      &--2 {
        width: fit-content;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        & h3 {
          color: var(--color-blue-dark) !important;
          font-weight: 600;
          font-size: 2.4rem;

          @include respond(tablet) {
            font-size: 2.2rem;
          }
        }

        & div {
          height: 0.5rem;
          width: 30%;
          background-color: var(--color-yellow);
          border-radius: 100rem;

          @include respond(tablet) {
            display: none;
          }
        }
      }
    }

    &__values {
      margin-top: 10rem;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
      grid-gap: 10vw;
      padding: 0 6rem;

      @include respond(tablet) {
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
      }
    }

    &__value {
      background-color: #e7e7e7;
      padding: 0.8rem;
      padding-bottom: 1.5rem;
      border-radius: 0.7rem;
      position: relative;
      align-self: stretch;
      max-height: 50rem;

      @include respond(tablet) {
        max-height: fit-content;
      }

      &:not(:last-child) {
        margin-bottom: 6rem;
      }

      & figure:first-child {
        height: fit-content;

        & > div {
          width: 100%;
        }
        & img {
          object-fit: fill;
        }
      }

      & > div {
        position: absolute !important;
        z-index: -10;

        @include respond(tablet) {
          display: none !important;
        }
      }
      &:first-child > div {
        top: -5rem;
        right: -7rem;
      }

      &:nth-child(2) > div {
        top: -5rem;
        right: -9rem;
      }

      &:nth-child(3) > div {
        top: -5rem;
        right: -11rem;
      }

      &__line {
        margin-top: 3rem;
        margin-bottom: 3rem;
        border-radius: 100rem;
        height: 0.7rem;
        background-color: var(--color-yellow);
        width: 40%;
        min-width: 10rem;
      }

      & h3 {
        font-size: 1.8rem;
        color: var(--color-blue-dark);
        font-weight: 700;
        text-transform: uppercase;
      }

      & p {
        margin-top: 3rem;
        color: black;
        line-height: 2.5rem;
        max-width: 80%;
        font-size: 1.3rem;
      }
    }

    &__carousel-item-details {
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 3rem 2rem;
      text-align: left;
      background-color: #fff;
      height: 70%;

      h3 {
        font-size: 1.8rem;
        color: var(--color-blue-dark);
        font-weight: 700;
        text-transform: capitalize;
      }

      p {
        font-size: 1.3rem;
        line-height: 1.5;
        color: #153a7e;
        font-weight: 400;
      }
    }

    &__partners {
      padding: 3rem 0;

      h3 {
        margin-bottom: 2rem;
        font-size: 2.5rem;
      }

      &__partner-logos {
        img {
          width: 150px;
          padding: 1rem 2rem;
        }
      }
    }

    @include respond(mobile) {
      margin-top: 10px;
    }
  }
}
.carousel-wrapper {
  // margin-top: 3rem;
  padding: 2rem 7rem;

  @include respond(tablet) {
    padding: 2rem 2rem;
  }

  &--youtube {
    background: rgba(196, 196, 196, 0.12);
  }

  &--testimonial {
    background-image: url(../../assets//images//testimonial-background.png);
  }

  &--desktop {
    @include respond(medium) {
      display: none;
    }
  }
}

.youtube-carousel-item {
  position: relative;

  & > div:first-child {
    width: 90%;

    @include respond(tablet) {
      width: 100%;
    }
  }

  & figure {
    top: 50%;
    left: 35%;
    transform: translateY(-50%) scale(0.6);
    position: absolute;
    cursor: pointer;
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    cursor: pointer;

    @include respond(tablet) {
      width: 100%;
    }

    &:hover {
      opacity: 0.5;

      & ~ figure {
        width: 10rem;
      }
    }
  }
}

.blog-carousel-item {
  cursor: pointer;
  & > div {
    width: 90%;

    @include respond(tablet) {
      width: 100%;
    }
  }

  & h2 {
    color: var(--color-blue-dark);
    font-size: 1.7rem;
    font-weight: 600;
    margin-top: 2rem;
    max-width: 90%;
  }
}

.blog__wrapper {
  margin-top: 3rem;
  padding: 7rem 7rem;

  &--mobile {
    display: none;
    @include respond(medium) {
      display: block;
      padding: 7rem 2rem;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  }

  &__link {
    display: block;
    font-size: 1.7rem;
    width: fit-content;
    margin: auto;
    margin-top: 3rem;
  }
}
.testimonial-carousel-item {
  background-color: #fff;
  margin-top: 3rem;
  width: 90%;
  padding: 2rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond(tablet) {
    width: 100%;
  }

  & div:first-child {
    max-width: 90%;
    font-size: 1.1rem;
    line-height: 2rem;

    @include respond(tablet) {
      max-width: 100%;
    }
  }

  & div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & span {
      font-weight: 700;
      font-size: 1.3rem;
    }
  }
}
