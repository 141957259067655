.form {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f7fb;
  border-radius: 8px;
}

.get-quotes-form {
  width: 100%;

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 28px;
    color: #22428f;
    text-align: left;
    margin-bottom: 1.5rem;
    padding-top: 1.8rem;
  }

  .quote-form-item {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .column {
    width: 100%;
    height: 48px;
    margin-bottom: 2rem;
    background: #ffffff;
    border-radius: 8px;
    text-align: left;

    &:nth-child(1) {
      margin-top: 15px;
    }

    &:nth-child(2) {
      margin-top: 25px;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root
      .MuiOutlinedInput-root {
      padding-right: 10px;
      background-color: #ffffff;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root
      .MuiOutlinedInput-root
      ::placeholder {
      font-size: 12px;
    }
  }

  .get-quote-submit-button {
    width: 100%;
    color: #ffffff;
    background: #22428f;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-transform: inherit;
    padding: 20px 0;
    margin-bottom: 10px;
  }

  .get-quote-submit-button:hover {
    background: #22428f;
  }

  .quotes-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #0f1414;
    text-align: left;
  }

  .quote-weight {
    margin-bottom: 2rem;
  }

  .quote-weight-details {
    margin-top: 10px;
  }

  #weight::placeholder {
    font-size: 12px;
  }
}

.validation-error {
  color: red;
  padding: 5px 0;
  text-align: left;
  &.footer-error-message {
    padding: 0;
  }
}

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
  .form {
    width: 100%;

    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f7fb;
    border-radius: 8px;
  }

  .get-quotes-form {
    width: 100%;

    h2 {
      font-size: 32px;
      font-weight: 600;
      line-height: 28px;
      color: #22428f;
      text-align: left;
      margin-bottom: 2rem;
    }

    .quote-form-item {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    .sender-details,
    .receiver-details {
      display: flex;
      // justify-content: space-between;
      flex-direction: column;
    }

    .column {
      width: 100%;
      min-width: 195px;
      height: 48px;
      background: #ffffff;
      border-radius: 12px;
      text-align: left;
      margin-top: 0px;

      &:nth-child(1) {
        margin-top: 0px;
      }

      &:nth-child(2) {
        margin-top: 0px;
      }
    }

    .get-quote-submit-button {
      width: 100%;
      color: #ffffff;
      background: #22428f;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-transform: inherit;
      padding: 20px 0;
      margin-bottom: 8px;
    }

    .get-quote-submit-button:hover {
      background: #22428f;
    }

    .quotes-label {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #0f1414;
      text-align: left;
      margin-bottom: 10px;
    }

    .quote-weight {
      margin-bottom: 2rem;
    }

    .quote-weight-details p {
      margin-bottom: 0;
    }

    .validation-error {
      color: red;
      padding: 5px 0;
      text-align: left;
    }
  }

  .MuiOutlinedInput-root {
    background-color: #ffffff;
  }
}
