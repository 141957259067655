.input-group {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & input,
  & select {
    padding: 1.4rem;
    border: none;
    border-radius: 0.8rem !important;
    background-color: white;
    font-size: 1.5rem;
    color: #6b6b6b;

    &:focus {
      outline: none;
    }
  }

  & label {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    color: black;
    font-weight: 500;
  }
}
