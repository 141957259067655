.campaign-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5rem;
    background-color: rgb(0 0 0 / 83%);
    z-index: 3;
    animation: fadeIn 1s;
    transition: all 0.4s 0.2s;
    
    &__image{
      height: auto;
      width: 100%;
      border-radius: 0.5rem;
      margin-bottom: 4rem;
    }
    
    &__close-icon{
        height: 5rem;
        width: 5rem;
        cursor: pointer;
        transition: all 0.4s 0.2s;
        opacity: 0;
        animation: fadeIn 1s ease-in forwards;
        animation-delay: 4s;
    }
  }

  .show-modal {
    display: flex;
  }
  
  .close-modal {
    display: none;
  }

  @media screen and (min-width: 60rem) {
    .campaign-modal {
        padding: 30rem;
    
        &__image{
            width: 100%;
           
        }
    
        &__close-icon{
            height: 7rem;
            width: 7rem;
        }
      }
  }