body {
  font-weight: 400;
  line-height: 1.15;
  color: var(--color-text);
  font-family: "General Sans", sans-serif;
  // font-family: "Montserrat", sans-serif;
}

.h2 {
  color: var(--color-headings);
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 1rem;
}

.h1 {
  color: var(--color-headings);
  font-size: 2rem;
  font-weight: 700;
}
